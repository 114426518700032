export const regionsList = [
    'Alaska',
    'Africa & Middle East',
    'Asia',
    'Asia Orient',
    'Arabia/Asia',
    'Australia & New Zealand',
    'British Isles & Ireland',
    'Canada/New England',
    'Canada & New England',
    'Caribbean',
    'Caribbean & Central America',
    'California Coast',
    'Coastal',
    'Europe',
    'Galapagos',
    'Hawaii/Tahiti',
    'Hawaii',
    'Mediterranean',
    'North America',
    'Northern Africa & Middle East',
    'Orient/Asia',
    'Polynesian',
    'Scandinavia',
    'South America',
    'South America & Antarctica',
    'South Pacific',
    'Tahiti',
    'Tahiti & South Pacific',
    'Trans-Atlantics',
    'The Arctic',
    'Panama Canal',
    'World',
    'World Voyage',
    'World Cruises',
]
