import React from 'react'

import Accordion from 'components/blocks/Accordion/Accordion/Accordion'
import DescriptionListDisplay from 'components/blocks/DescriptionListDisplay/DescriptionListDisplay'

import styles from './SailingInfo.module.css'
import allContent from '../../../../content/content'
import { SailingInfoModel } from 'api-data-models/cruise-detail/SailingInfoModel'

const content = allContent.cruise.sailingPage.informationSection

const SailingInfo = ({ sailingInfoData }: { sailingInfoData: SailingInfoModel }): JSX.Element => {
    const {
        cruiseId: cruiseTextId,
        departingDate,
        returningDate,
        duration,
        roundTrip,
    } = content.informationKeys

    const informationDataListLeft = sailingInfoData ? (
        <DescriptionListDisplay
            className={styles.columns}
            isAlternatingColor
            keyValueContent={[
                [cruiseTextId, sailingInfoData.cruiseId],
                [departingDate, sailingInfoData.embarkDate],
                [returningDate, sailingInfoData.disembarkDate],
                [duration, sailingInfoData.duration],
            ]}
        />
    ) : null

    const { supplierName, shipName } = content.informationKeys
    const informationDataListRight = sailingInfoData ? (
        <DescriptionListDisplay
            className={styles.columns}
            isAlternatingColor
            keyValueContent={[
                [supplierName, sailingInfoData.supplierName],
                [shipName, sailingInfoData.shipName],
                [roundTrip, sailingInfoData.roundTrip],
            ]}
        />
    ) : null

    const sailingInfoTable = (
        <Accordion
            baseId={`info-section-${sailingInfoData?.cruiseId ? sailingInfoData.cruiseId : ''}`}
            title={content.heading}
            titleSize='3'
            titleLevel='2'
            darkHeading={true}
            isPadded={true}
            startOpen={true}
        >
            <div className={styles.container}>
                {informationDataListLeft}
                {informationDataListRight}
            </div>
        </Accordion>
    )
    return <>{!!sailingInfoData && sailingInfoTable}</>
}

export default SailingInfo
