import React, { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Breadcrumb from 'components/basics/Breadcrumb/Breadcrumb'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import OrderLayout from '../../layouts/order/OrderLayout/OrderLayout'
import { ROUTES } from 'components/sections/app/AppRoutes'
import {
    OrderContent,
    API_PRODUCT_TYPES,
    PassengerCriteria,
    EKS_API_PRODUCT_TYPES,
} from 'api-data-models/order/OrderContentModel'
import createApolloClient from 'utils/apollo-client/create-apollo-client'
import { getFormattedDateTimeToLocalTimezone } from 'utils/date-helpers'
import allContent from 'content/content'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import styles from './OrderPage.module.css'
import { useRest } from 'components/hooks/useRest'
import { orderServiceUrls } from 'utils/order-service-urls'
import { convertKeysToDirectCase } from 'utils/camel-case-helpers'
import { HTTP_METHODS } from 'utils/constants'

const content = allContent.order.orderPage
const breadcrumbContent = allContent.app.breadcrumbs

const l3ApiClient = createApolloClient(process.env.REACT_APP_L3_API_URL)

export type OptionalExtrasArguments =
    | undefined
    | {
          id: string
          supplierCode: string
          rateCode: string
          cabinGradeCode: string
          cabinNumber: string
          passengers: {
              passengers?: {
                  age: number
                  travellerNumber: number
                  military?: boolean
                  residency?: string
                  pastPassengerReference?: string | null
              }[]
          }
      }

export const shouldSkipReprice = (orderData: Record<string, any>, isNewOrder: boolean): boolean => {
    return Boolean(
        (orderData?.items[0].bookedAt && orderData?.items[0].supplierOrderReference) ||
            (isNewOrder && orderData?.items[0].createdAt)
    )
}

const getDefaultOptionalExtrasVariables = (
    orderAPIData: Record<string, any>
): OptionalExtrasArguments | undefined => {
    if (orderAPIData) {
        const isCruiseItem =
            orderAPIData?.items[0]?.product?.__typename === API_PRODUCT_TYPES.CRUISE ||
            orderAPIData?.items[0]?.type === EKS_API_PRODUCT_TYPES.CRUISE
        if (isCruiseItem) {
            const resultProduct = orderAPIData?.items[0].product
            return {
                id: resultProduct?.id,
                supplierCode: resultProduct?.cruiseLineCode,
                rateCode: resultProduct?.rateCode,
                cabinGradeCode: resultProduct?.cabinGradeCode,
                cabinNumber: resultProduct?.cabinNumber,
                passengers: {
                    passengers: orderAPIData?.items[0].passengersCriteria?.passengers.map(
                        (el: PassengerCriteria) => ({
                            age: el.age,
                            travellerNumber: el.travellerNumber,
                            military: el.military,
                            residency: el.residency,
                            pastPassengerReference: el.pastPassengerReference,
                        })
                    ),
                },
            }
        }
    }
    return undefined
}

/** OrderPage: returns either a component that renders the passenger page, or error */
export const OrderPage: React.FC<{
    apiClient: ApolloClient<NormalizedCacheObject>
    skipReprice: boolean
    orderAPIData: Record<string, any>
    getOrderCalledOnce: boolean
}> = ({ apiClient, skipReprice, orderAPIData, getOrderCalledOnce }): JSX.Element => {
    const [orderResult, setOrderResult] = useState<OrderContent | undefined>(
        skipReprice ? new OrderContent(orderAPIData) : undefined
    )

    const [optionalExtrasVariables, setOptionalExtrasVariables] = useState<OptionalExtrasArguments>(
        getDefaultOptionalExtrasVariables(orderAPIData)
    )

    const [isRepricing, setIsRepricing] = useState<boolean>(!skipReprice)
    const [isPriceDifferent, setIsPriceDifferent] = useState<boolean>(false)
    const initialTimestamp = orderAPIData.items[0].bookedAt ?? orderAPIData.items[0].createdAt
    const [lastPriceUpdateTimeStamp, setLastPriceUpdateTimeStamp] = useState<string>(
        getFormattedDateTimeToLocalTimezone(initialTimestamp)
    )

    const optionalExtraParams = {
        orderId: orderAPIData?.id,
        itemId: orderAPIData?.items[0]?.id,
        supplierCode: orderAPIData?.items[0]?.product?.cruiseLineCode,
    }

    const isParamsInValid = Object.values(optionalExtraParams).some((param) => !param)

    const {
        result: updateOptionalExtrasResult,
        loading: updateOptionalExtrasLoading,
        error: updateOptionalExtrasError,
    } = useRest({
        url: orderServiceUrls.updateOrderOptionalExtras(
            optionalExtraParams.orderId,
            optionalExtraParams.itemId,
            optionalExtraParams.supplierCode
        ),
        method: HTTP_METHODS.POST,
        source: 'OrderPage - UPDATE_ORDER_OPTIONAL_EXTRAS',
        skip: skipReprice || getOrderCalledOnce || isParamsInValid,
        variables: [],
    })

    const updateOptionalExtrasResultParsed = useMemo(
        () => updateOptionalExtrasResult && convertKeysToDirectCase(updateOptionalExtrasResult),
        [updateOptionalExtrasResult]
    )

    useEffect(() => {
        setOptionalExtrasVariables(getDefaultOptionalExtrasVariables(orderAPIData))
    }, [orderAPIData])

    useEffect(() => {
        setIsRepricing(updateOptionalExtrasLoading)
        let currentOrder = orderAPIData
        let latestUpdatedDateTime = orderAPIData?.items[0].createdAt
        if (updateOptionalExtrasResultParsed || updateOptionalExtrasError) {
            const isCruiseItem =
                orderAPIData?.items[0]?.product?.__typename === API_PRODUCT_TYPES.CRUISE ||
                orderAPIData?.items[0]?.type === EKS_API_PRODUCT_TYPES.CRUISE
            if (isCruiseItem && updateOptionalExtrasResultParsed) {
                latestUpdatedDateTime = new Date().toString()
                const cruiseProduct = orderAPIData?.items[0]?.product
                const latestPrice = updateOptionalExtrasResultParsed.items[0].product.price

                const shouldBeChanged = cruiseProduct?.price
                    ? latestPrice !== cruiseProduct.price
                    : latestPrice !== cruiseProduct.priceItems[0].totalGrossPrice
                if (shouldBeChanged) {
                    setIsPriceDifferent(true)
                    currentOrder = updateOptionalExtrasResultParsed
                }
            }
            setLastPriceUpdateTimeStamp(getFormattedDateTimeToLocalTimezone(latestUpdatedDateTime))
            const order = new OrderContent(currentOrder)
            setOrderResult(order)
            setIsRepricing(false)
        }
    }, [
        orderAPIData,
        updateOptionalExtrasResultParsed,
        updateOptionalExtrasError,
        updateOptionalExtrasLoading,
    ])

    return (
        <div>
            {(isRepricing || (!orderResult && !updateOptionalExtrasError)) && (
                <LargeSpinner text={content.fetchingLatestPricing} />
            )}
            {(updateOptionalExtrasError || isPriceDifferent) && (
                <div className={styles['info-messages-container']}>
                    {updateOptionalExtrasError && (
                        <ErrorList
                            errorsList={updateOptionalExtrasError}
                            source='OrderPage - Repricing API error'
                        />
                    )}
                    {isPriceDifferent && (
                        <InfoBanner
                            id='repricing-api-error-banner'
                            bannerType='warning'
                            text={content.pricingDifferentInfo}
                            isCloseable={false}
                        />
                    )}
                </div>
            )}
            {!isRepricing && orderResult && (
                <OrderLayout
                    lastPriceUpdateTimeStamp={lastPriceUpdateTimeStamp}
                    optionalExtrasVariables={optionalExtrasVariables}
                    orderData={orderResult}
                    orderApiClient={apiClient}
                    l3ApiClient={l3ApiClient}
                />
            )}
        </div>
    )
}

const OrderPageWrapper: React.FC<{ apiClient: ApolloClient<NormalizedCacheObject> }> = ({
    apiClient,
}): JSX.Element => {
    /** parse query params for api call below */
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const orderId = queryParams.get('orderId') ?? ''

    const isNewOrder = queryParams.get('new-order') === 'yes'

    const [getOrderCalledOnce, setGetOrderCalledOnce] = useState<boolean>(false)

    const {
        result: getOrderResult,
        loading: getOrderLoading,
        error: getOrderError,
    } = useRest({
        url: orderServiceUrls.getOrder(orderId),
        method: 'GET',
        source: 'OrderPage - GET_ORDER',
    })

    const orderResultParsed = getOrderResult && convertKeysToDirectCase(getOrderResult)

    let skipReprice = true
    if (orderResultParsed) {
        skipReprice = shouldSkipReprice(orderResultParsed, isNewOrder)
    }

    useEffect(() => {
        if (orderResultParsed) {
            setGetOrderCalledOnce(true)
        }
    }, [orderResultParsed, setGetOrderCalledOnce])

    const breadcrumbItems = [
        { text: breadcrumbContent.search, url: ROUTES.CRUISE_SEARCH },
        { text: breadcrumbContent.allOrders, url: ROUTES.ALL_ORDERS },
        { text: breadcrumbContent.cart },
    ]

    return (
        <div className='general-container'>
            <Breadcrumb urlList={breadcrumbItems} />
            {getOrderLoading && <LargeSpinner text={content.fetchingOrder} />}
            {getOrderError && (
                <ErrorList errorsList={getOrderError} source='OrderPage - Repricing API error' />
            )}
            {!orderId && (
                <InfoBanner
                    id='api-error-banner'
                    bannerType='error'
                    text={`${content.incorrectParams} ${queryParams}`}
                    isCloseable={false}
                    logType='warn'
                    source='order-page'
                />
            )}
            {!!orderResultParsed && !getOrderLoading && (
                <OrderPage
                    apiClient={apiClient}
                    skipReprice={skipReprice}
                    orderAPIData={orderResultParsed}
                    getOrderCalledOnce={getOrderCalledOnce}
                />
            )}
        </div>
    )
}

export default OrderPageWrapper
