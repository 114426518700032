import React from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import OrderBookedPassengerDetails from 'components/sections/order/OrderBookedPassengerDetails/OrderBookedPassengerDetails'
import Heading from 'components/basics/Heading/Heading'
import Spacing from 'components/basics/Spacing/Spacing'
import OrderPricingSection from 'components/sections/order/OrderPricingSection/OrderPricingSection'
import OrderConfirmation, {
    orderConfirmationHeading,
} from 'components/sections/order/OrderConfirmation/OrderConfirmation'
import getRescardHtmlComment from 'utils/trams-rescard/getResCardHtmlComment'
import {
    CruiseProduct,
    FlightProduct,
    OrderContent,
    PRODUCT_TYPES,
} from 'api-data-models/order/OrderContentModel'
import { getFormattedDateTimeToLocalTimezone } from 'utils/date-helpers'
import {
    CruiseSummaryPrices,
    getAdvisoryMessages,
    getSummaryPrices,
} from '../OrderLayout/OrderLayout'

import styles from '../OrderLayout/OrderLayout.module.css'
import allContent from 'content/content'

const content = allContent.order.orderPage

type OrderConfirmationLayoutProps = {
    orderData: OrderContent
}
const OrderConfirmationLayout = ({
    orderData,
}: OrderConfirmationLayoutProps): JSX.Element | null => {
    const orderItem = orderData.orderItem
    const supplierOrderItemReference: string = orderItem.supplierOrderItemReference as string

    let product: CruiseProduct | FlightProduct = {} as CruiseProduct | FlightProduct
    let cruiseSummaryPrices = null
    if (orderItem?.product?.productType === PRODUCT_TYPES.CRUISE) {
        product = orderItem.product as CruiseProduct
        const passengersCriteria = orderItem?.passengersCriteria || []
        const defaultCruiseExtras = passengersCriteria?.[0]?.assignedOptionalExtras
        cruiseSummaryPrices = getSummaryPrices(
            product.pricing.totalGrossPriceCent,
            defaultCruiseExtras
        )
    } else if (orderItem?.product?.productType === PRODUCT_TYPES.FLIGHT) {
        product = orderItem.product as FlightProduct
    }

    const orderIdTrimmed = orderData.orderId ? orderData.orderId.slice(0, 8) : ''

    const orderItemStatus = orderItem.orderItemStatus
    const orderItemPaymentOption = orderItem.orderItemPaymentOption

    const assignedTravellers = orderData.orderItem.assignedTravellers
    const leadTraveller = orderData.orderItem.leadTraveller
    const travellers = { ...orderData.travellers }
    const numberOfPassengers = orderData.orderItem.numberOfTravellers
    const contactDetails = orderData.contactDetails

    let rescardComment

    try {
        rescardComment = getRescardHtmlComment({ order: orderData })
    } catch (error) {
        datadogLogs.logger.log(`error creating RESCARD: ${JSON.stringify(error)}`)
    }

    const advisoryMessages = orderItem?.advisory ? getAdvisoryMessages(orderItem.advisory) : null

    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <Spacing>
                    <Heading heading='1'>{content.confirmationPage.headingTitle}</Heading>
                </Spacing>
            </div>
            {orderConfirmationHeading({ orderItemPaymentOption, orderItemStatus })}
            {advisoryMessages}
            <div className={styles.content}>
                <div className={styles['content-left']}>
                    <OrderConfirmation
                        numberOfTravellers={numberOfPassengers}
                        orderItemStatus={orderItemStatus}
                        orderItemPaymentOption={orderItemPaymentOption}
                        product={product}
                        supplierOrderItemReference={supplierOrderItemReference}
                        orderId={orderIdTrimmed}
                    />
                    <div className={styles.bottom}>{!!rescardComment && rescardComment}</div>
                </div>
                <div className={styles['content-right']}>
                    <OrderPricingSection
                        lastPriceUpdateTimeStamp={getFormattedDateTimeToLocalTimezone(
                            orderData.orderItem.bookedAt
                        )}
                        product={product}
                        orderId={orderIdTrimmed}
                        cruiseSummaryPrices={cruiseSummaryPrices as CruiseSummaryPrices}
                    />
                    <OrderBookedPassengerDetails
                        travellers={travellers}
                        assignedTravellerNumbers={assignedTravellers}
                        contactDetails={contactDetails}
                        leadTravellerNumber={leadTraveller as number}
                    />
                </div>
            </div>
        </div>
    )
}
export default OrderConfirmationLayout
