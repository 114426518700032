import React, { useContext, useState } from 'react'

import Button from 'components/basics/Button/Button'
import DescriptionListDisplay from 'components/blocks/DescriptionListDisplay/DescriptionListDisplay'
import Heading from 'components/basics/Heading/Heading'
import Modal from 'components/blocks/Modal/Modal'
import Text from 'components/basics/Text/Text'
import PriceBreakdownView from 'components/sections/cruise/PriceBreakdownView/PriceBreakdownView'
import SendQuoteModal from 'components/layouts/order/SendQuoteModal/SendQuoteModal'
import Tooltip from 'components/basics/Tooltip/Tooltip'
import { copyToClipboardHandler } from 'utils/file-system-helpers'
import getOrderQuote from 'utils/order-quote/getOrderQuote'
import { ORDER_TEMPLATE_HTML } from 'utils/order-quote/HtmlTemplate'
import { createStringFromList } from 'utils/string-helpers'
import { getFormattedDate } from 'utils/date-helpers'
import { ORDER_TEMPLATE_TEXT } from 'utils/order-quote/TextTemplate'
import {
    CruiseProduct,
    ORDER_ITEM_PAYMENT_TYPE,
    ORDER_ITEM_STATUS,
} from 'api-data-models/order/OrderContentModel'
import { FeatureToggleContext } from 'App'
import styles from './CruiseOrderItem.module.scss'
import allContent from 'content/content'

const content = allContent.order.orderPage.cruiseOrderItem
type CruiseOrderItemProps = {
    cruiseProduct: CruiseProduct
    numberOfPassengers: number
    supplierOrderItemReference: string | null
    orderItemStatus: string
    orderItemPaymentOption?: string
    orderId: string
}

const CruiseOrderItem = ({
    cruiseProduct,
    numberOfPassengers,
    supplierOrderItemReference,
    orderItemStatus,
    orderItemPaymentOption,
    orderId,
}: CruiseOrderItemProps): JSX.Element => {
    const featureToggles = useContext(FeatureToggleContext)

    const [isPriceModalOpen, setIsPriceModalOpen] = useState<boolean>(false)
    const [isSendQuoteModalOpen, setIsSendQuoteModalOpen] = useState<boolean>(false)
    const [clickedButtonId, setClickedButtonId] = useState('')
    const handleOpenModal = ({ buttonId }: { buttonId: string }): void => {
        setClickedButtonId(buttonId)
        setIsPriceModalOpen(true)
    }
    const embarkDateUS = getFormattedDate(cruiseProduct.embarkDate)
    const disembarkDateUS = getFormattedDate(cruiseProduct.disembarkDate)
    const cruiseInfo = createStringFromList(
        [
            `${embarkDateUS} - ${disembarkDateUS}`,
            `${cruiseProduct.duration} ${content.nights}`,
            `${numberOfPassengers} ${content.passengers}`,
        ],
        ' • '
    )
    const cabinGradeInfo = createStringFromList([
        cruiseProduct.cabinGradeName,
        cruiseProduct.cabinGradeDescription,
        cruiseProduct.cabinGradeCode,
    ])
    const deckInfo = createStringFromList([cruiseProduct.deckLevel, cruiseProduct.deckName])
    const rateCodeInfo = createStringFromList([
        cruiseProduct.rateCodeName,
        cruiseProduct.rateCodeDescription,
        cruiseProduct.rateCode,
    ])
    const shipName = cruiseProduct.shipName
    const cruiseLine = cruiseProduct.lineName
    const itinerary = cruiseProduct.itinerary.portListContentFull
    const cruiseDetailsMaxKeyLength = Math.max(
        ...[
            content.itinerary.length,
            content.cabinType.length,
            content.cabinNumber.length,
            content.deck.length,
            content.rateCode.length,
            content.cruiseId.length,
            content.supplierName.length,
            content.shipName.length,
        ]
    )

    const isBooked =
        orderItemStatus === ORDER_ITEM_STATUS.BOOKED ||
        orderItemStatus === ORDER_ITEM_STATUS.ON_HOLD

    const renderStatus = (): string => {
        if (isBooked) {
            if (orderItemStatus === ORDER_ITEM_STATUS.ON_HOLD) {
                if (orderItemPaymentOption === ORDER_ITEM_PAYMENT_TYPE.ONHOLD) {
                    return `- ${content.statuses.onHold}`
                } else if (orderItemPaymentOption !== null) {
                    return `- ${content.statuses.revertedToOnHold}`
                }
            } else {
                if (orderItemPaymentOption === ORDER_ITEM_PAYMENT_TYPE.DEPOSIT)
                    return `- ${content.statuses.deposit}`
                if (orderItemPaymentOption === ORDER_ITEM_PAYMENT_TYPE.FULL)
                    return `- ${content.statuses.fullPayment}`
            }
        }
        return ''
    }

    const handleCruiseCopy = ({ buttonId }: { buttonId: string }): void => {
        if (buttonId === 'copy-cruise-info') {
            const orderTemplate = ORDER_TEMPLATE_HTML
            const orderQuoteHtml = getOrderQuote({
                orderTemplate,
                cruiseProduct,
                supplierOrderItemReference,
                numberOfPassengers,
            })
            navigator.clipboard.writeText(orderQuoteHtml)
        } else {
            const orderTemplate = ORDER_TEMPLATE_TEXT
            const orderQuoteText = getOrderQuote({
                orderTemplate,
                cruiseProduct,
                supplierOrderItemReference,
                numberOfPassengers,
            })
            navigator.clipboard.writeText(orderQuoteText)
        }
    }

    const cruiseOrderItem = (
        <>
            <div className={styles[`order-item__header`]}>
                <Heading heading='2' onDarkBackground={true}>
                    {`${cruiseProduct.productType} ${renderStatus()}`}
                </Heading>
                <div className={styles['order-item__header-sub-text']}>
                    {isBooked && supplierOrderItemReference && (
                        <div className={styles['order-item__header-sub-text--supplier-ref']}>
                            <Text size='S' color='orange' weight='bold'>
                                {content.supplierOrderReference}
                            </Text>
                            <Text color='white' weight='bold'>
                                {supplierOrderItemReference}
                            </Text>
                            <Tooltip
                                content={content.copiedAfterClickText}
                                id='copy-supplier-reference'
                                openOnClick={true}
                            >
                                <Button
                                    onClick={(): Promise<void> =>
                                        copyToClipboardHandler(supplierOrderItemReference)
                                    }
                                    flavour='icon'
                                    onDarkBackground={true}
                                    text={content.copySupplierRefButton}
                                    type='button'
                                    iconName='Copy'
                                />
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>
            <div className={styles[`order-item__details`]}>
                <div className={styles[`order-item__details-itinerary`]}>
                    <div className={styles[`order-item__details-subheader`]}>
                        <Text size='M' weight='bold'>
                            {cruiseProduct?.cruiseName ?? cruiseProduct.productName}
                        </Text>
                    </div>
                    <Text>{cruiseInfo}</Text>
                </div>
                <div className={styles[`order-item__details-itinerary`]}>
                    <div className={styles[`order-item__details-subheader`]}>
                        <Text size='S' weight='bold'>
                            {content.itinerary}
                        </Text>
                    </div>
                    <span>
                        {itinerary.map((portName, i) => {
                            if (i === 0) {
                                return <Text key={portName + i}>{portName}</Text>
                            } else {
                                return <Text key={portName + i}> • {portName}</Text>
                            }
                        })}
                    </span>
                </div>
                <div>
                    <DescriptionListDisplay
                        overrideMaxKeyLength={cruiseDetailsMaxKeyLength}
                        keyValueContent={[
                            [content.shipName, shipName],
                            [content.supplierName, cruiseLine],
                            [content.cabinNumber, cruiseProduct.cabinNumber],
                            [content.cabinType, cabinGradeInfo],
                            [content.deck, deckInfo],
                            [content.rateCode, rateCodeInfo],
                            [content.cruiseId, cruiseProduct.cruiseId],
                        ]}
                    />
                </div>
            </div>
            <div className={styles['order-item__footer']}>
                <Button
                    id={'pricing-info-button'}
                    flavour='secondary'
                    text={content.pricingInfo}
                    disabled={
                        !cruiseProduct.breakdownPerPassenger ||
                        !Object.keys(cruiseProduct.breakdownPerPassenger).length
                    }
                    type='button'
                    onClick={(): void => handleOpenModal({ buttonId: 'pricing-info-button' })}
                />
                {featureToggles.TURN_ON_EMAIL_QUOTES && (
                    <Button
                        id={'send-quote-button'}
                        flavour='tertiary'
                        text={content.sendQuote}
                        type='button'
                        onDarkBackground={true}
                        onClick={(): void => setIsSendQuoteModalOpen(true)}
                    />
                )}
                <Tooltip
                    content={content.copiedAfterClickText}
                    id='copy-cruise-text'
                    openOnClick={true}
                >
                    <Button
                        id={'copy-cruise-text'}
                        flavour='tertiary'
                        iconName='Copy'
                        text={content.copyWithOutFormatting}
                        type='button'
                        onDarkBackground={true}
                        onClick={(): void => handleCruiseCopy({ buttonId: 'copy-cruise-text' })}
                    />
                </Tooltip>
            </div>
            {featureToggles.TURN_ON_EMAIL_QUOTES && (
                <SendQuoteModal
                    isOpen={isSendQuoteModalOpen}
                    onClose={(): void => setIsSendQuoteModalOpen(false)}
                    returnFocusId={'send-quote-button'}
                    templateData={{
                        cruise_name: cruiseProduct?.cruiseName ?? cruiseProduct.productName,
                        traveltek_ref: orderId,
                        embark_date: embarkDateUS,
                        disembark_date: disembarkDateUS,
                        duration: String(cruiseProduct.duration),
                        passengers_count: String(numberOfPassengers),
                        itinerary: itinerary.join(', '),
                        ship_name: shipName,
                        deck_number: deckInfo,
                        cruise_line: cruiseLine,
                        rate_code: rateCodeInfo,
                        cabin_type: cabinGradeInfo,
                        cabin_number: cruiseProduct.cabinNumber,
                        voyage_code: cruiseProduct.cruiseId,
                        price_currency_sym: cruiseProduct.pricing.currencySymbol,
                        price_fare: cruiseProduct.pricing.totalGrossPrice,
                        price_total: cruiseProduct.pricing.totalGrossPrice,
                        price_currency: cruiseProduct.pricing.currencyCode,
                    }}
                />
            )}
        </>
    )
    return (
        <div>
            {cruiseOrderItem}
            <Modal
                isOpen={isPriceModalOpen}
                headerText={content.priceBreakDown.header}
                returnFocusId={clickedButtonId}
                setClosed={(): void => setIsPriceModalOpen(false)}
            >
                <PriceBreakdownView
                    commission={cruiseProduct.pricing.commission}
                    breakdownPerPassenger={cruiseProduct.breakdownPerPassenger}
                    currency={cruiseProduct.pricing.currencySymbol}
                    totalGrossPrice={cruiseProduct.pricing.totalGrossPrice}
                    cabinGradeCode={cruiseProduct.cabinGradeCode}
                    rateCode={cruiseProduct.rateCode}
                    cabinGradeDescription={cruiseProduct.cabinGradeDescription}
                    rateCodeDescription={cruiseProduct.rateCodeDescription}
                    priceProps={{
                        military: cruiseProduct.pricing?.rateCodeProps?.military,
                        residency: cruiseProduct.pricing?.rateCodeProps?.residency,
                        refundPolicy: cruiseProduct.pricing?.rateCodeProps?.refundPolicy,
                        onBoardCredits: cruiseProduct.pricing.totalObcPrice,
                    }}
                />
            </Modal>
        </div>
    )
}

export default CruiseOrderItem
