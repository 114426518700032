import React, { useCallback } from 'react'

import Card from 'components/blocks/Card/Card'
import Radio from 'components/basics/Input/Radio/Radio'
import Text from 'components/basics/Text/Text'
import { Cabin, Location } from 'api-data-models/CabinContentModel'
import classnames from 'classnames'

import styles from './CabinPickerTable.module.css'
import allContent from 'content/content'

const content = allContent.cruise.cabinPage.cabinPickerSection.selectList

type CabinPickerTableProps = {
    /** all cabins, data from api call (including guaranteed cabin which is left out of list for mapping) */
    cabins: Record<string, any>
    /** call back to set form value */
    setValue(key: string, value: string): void
    /** current form value for selectedCabinNumber */
    selectedCabinNumber: string
}

const CabinPickerTable: React.FC<CabinPickerTableProps> = ({
    cabins,
    setValue,
    selectedCabinNumber,
}) => {
    const handleOnCabinChange = useCallback(
        (value: string): void => {
            setValue('selectedCabinNumber', value)
        },
        [setValue]
    )
    const getLocationString = (location: Location): string => {
        const part2 = location.frontMiddleBack.toLowerCase()
        const part3 = location.insideOceanview.toLowerCase()
        const part1 = location.sideOfShip.toLowerCase()
        return `${part1}, ${part2}, ${part3}`
    }

    const cabinRows = cabins.map((cabin: Cabin) => {
        const isCabinSelected = cabin.number === selectedCabinNumber
        const tableBodyRowClassNames = classnames(styles['table-body-row'], {
            [styles['selected']]: isCabinSelected,
        })
        return (
            <tr
                className={tableBodyRowClassNames}
                onClick={(): void => handleOnCabinChange(cabin.number)}
                key={cabin.number}
            >
                <td>
                    <Radio
                        onChange={(): void => handleOnCabinChange(cabin.number)}
                        text={cabin.number}
                        checked={selectedCabinNumber === cabin.number}
                        hideLabel={true}
                    />
                </td>
                <td>
                    <Text weight='bold'>{cabin.number}</Text>
                </td>
                <td>
                    <Text>{cabin.deck.name}</Text>
                </td>
                <td>
                    <Text>{getLocationString(cabin.location)}</Text>
                </td>
                <td>
                    <Text>{cabin.obstructedView}</Text>
                </td>
                <td>
                    <Text>{cabin.accessibility}</Text>
                </td>
            </tr>
        )
    })

    return (
        <Card header={content.heading} headerSize='3'>
            <table className={styles['table']}>
                <thead>
                    <tr>
                        <td></td>
                        <td>
                            <Text weight='bold'>{content.name}</Text>
                        </td>
                        <td>
                            <Text weight='bold'>{content.deck}</Text>
                        </td>
                        <td>
                            <Text weight='bold'>{content.location}</Text>
                        </td>
                        <td>
                            <Text weight='bold'>{content.obstructed}</Text>
                        </td>
                        <td>
                            <Text weight='bold'>{content.accessibility}</Text>
                        </td>
                    </tr>
                </thead>
                <tbody>{cabinRows}</tbody>
            </table>
        </Card>
    )
}

export default CabinPickerTable
