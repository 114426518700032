import { gql } from '@apollo/client'

export const GET_CRUISES = gql`
    query GetCruises(
        $embarkLatestDate: Date
        $embarkEarliestDate: Date
        $destination: String
        $durationMax: Int
        $durationMin: Int
        $supplierCode: String
        $shipName: String
        $embarkPort: String
        $cruiseId: String
    ) {
        cruises(
            cruiseId: $cruiseId
            embarkPort: $embarkPort
            embarkLatestDate: $embarkLatestDate
            embarkEarliestDate: $embarkEarliestDate
            destination: $destination
            durationMax: $durationMax
            durationMin: $durationMin
            shipName: $shipName
            supplierCode: $supplierCode
        ) {
            resultsMetaData {
                numberOfResults
                cruiseLines
                shipNames
                cabinTypes
                durations
                embarkPorts
                disembarkPorts
                allPorts
                minPrice
                maxPrice
            }
            searchResults {
                duration
                embarkDate
                generalDestination
                id
                product {
                    name
                    description
                }
                ship {
                    code
                    name
                    line {
                        code
                        name
                    }
                }
                shipImage
                supplierLogo
                itineraryItems {
                    arrivalTime
                    dayNumber
                    departureTime
                    geolocation {
                        centrePoint {
                            lat
                            lng
                        }
                    }
                    itemDate
                    itineraryItemType
                    portCode
                    portName
                }
                leadInPrices {
                    fare
                    cabinType
                    available
                    taxesFeesAndPortExpenses
                    currency
                }
            }
        }
    }
`

export const GET_LOCATIONS_BY_SUBSTRING = gql`
    query GetLocationsBySubstring($substring: String!, $size: Int!) {
        getLocationsBySubstring(substring: $substring, size: $size) {
            name
            category
        }
    }
`

export const GET_USA_STATE_BY_IATA = gql`
    query GetUsaStateByIata($iata: String!) {
        getUsaStateByIata(iata: $iata) {
            name
        }
    }
`

export const GET_IATA_BY_SUBSTRING = gql`
    query GetIataBySubstring($substring: String!, $numberOfLocations: Int!) {
        getIataByPlacenameSubString(substring: $substring, numberOfLocations: $numberOfLocations) {
            iata
            placeType
            name
        }
    }
`

export const GET_SAILING_BY_CRUISE_ID = gql`
    query GetCruiseByCruiseId(
        $cruiseId: String!
        $supplierCode: String!
        $passengers: Passengers!
    ) {
        cruise(id: $cruiseId, supplierCode: $supplierCode, passengers: $passengers) {
            disembarkDate
            disembarkPort
            duration
            embarkDate
            embarkPort
            id
            itineraryItems {
                arrivalTime
                dayNumber
                departureTime
                geolocation {
                    centrePoint {
                        lat
                        lng
                    }
                }
                itemDate
                itineraryItemType
                portCode
                portName
            }
            priceItems {
                cabinGrade {
                    available
                    cabinType
                    code
                    colorCode
                    description
                    name
                }
                currency
                rateCode
                totalFarePrice
                totalGrossPrice
                totalNcfPrice
                totalTfpePrice
            }
            product {
                description
                name
            }
            ship {
                name
                line {
                    name
                    code
                }
            }
            rateCodes {
                code
                description
                name
                rateType
                refundPolicy
                military
                residency
            }
        }
    }
`

export const GET_CABIN_BY_RATE_CODE_AND_CABIN_GRADE_CODE = gql`
    query GetCruiseByRateAndGrade(
        $cabinGradeCode: String!
        $rateCode: String!
        $supplierCode: String!
        $cruiseId: String!
        $passengers: Passengers!
    ) {
        cruise(
            id: $cruiseId
            rateCode: $rateCode
            supplierCode: $supplierCode
            cabinGradeCode: $cabinGradeCode
            passengers: $passengers
        ) {
            diningOptions {
                dining {
                    availableInd
                    code
                    description
                    status {
                        code
                        description
                    }
                }
            }
            duration
            disembarkDate
            embarkDate
            id
            priceItems {
                breakdownItems {
                    code
                    name
                    passengerNumber
                    price
                    fareType
                    itemType
                }
                commission
                cabinGrade {
                    available
                    balcony
                    cabinType
                    cabins {
                        accessibility
                        amenity {
                            name
                            description
                        }
                        beddingConfiguration {
                            code
                            description
                        }
                        deck {
                            code
                            image
                            level
                            name
                        }
                        description
                        location {
                            frontMiddleBack
                            insideOceanview
                            sideOfShip
                        }
                        maxGuestCount
                        minGuestCount
                        number
                        obstructedView
                        roomSize
                    }
                    code
                    colorCode
                    description
                    guaranteed
                    name
                }
                currency
                rateCode
                totalFarePrice
                totalGrossPrice
                totalNcfPrice
                totalObcPrice
                totalTfpePrice
            }
            rateCodes {
                code
                description
                name
                rateType
                refundPolicy
            }
            ship {
                line {
                    name
                    code
                }
                name
            }
            product {
                name
            }
        }
    }
`

export const GET_PRICE_ITEM_BREAKDOWN = gql`
    query GetCruisePriceBreakdown(
        $rateCode: String!
        $supplierCode: String!
        $cabinGradeCode: String!
        $cruiseId: String!
        $passengers: Passengers!
    ) {
        cruise(
            rateCode: $rateCode
            supplierCode: $supplierCode
            cabinGradeCode: $cabinGradeCode
            passengers: $passengers
            id: $cruiseId
        ) {
            priceItems {
                breakdownItems {
                    code
                    fareType
                    itemType
                    name
                    passengerNumber
                    price
                }
                commission
                totalGrossPrice
                totalObcPrice
                totalNcfPrice
            }
        }
    }
`
