import React from 'react'

import {
    CruiseProduct,
    FlightProduct,
    PRODUCT_TYPES,
} from 'api-data-models/order/OrderContentModel'
import CruisePricingSection from './CruisePricingSection'
import styles from './OrderPricingSection.module.scss'

import allContent from 'content/content'
import Card from 'components/blocks/Card/Card'
import Text from 'components/basics/Text/Text'
import Heading from 'components/basics/Heading/Heading'
import { CruiseSummaryPrices } from 'components/layouts/order/OrderLayout/OrderLayout'

const content = allContent.order.orderPage.orderPricingSummary

type OrderPricingSectionProps = {
    product: CruiseProduct | FlightProduct
    orderId: string
    cruiseSummaryPrices: CruiseSummaryPrices | null
    lastPriceUpdateTimeStamp: string
}

const OrderPricingSummary = ({
    lastPriceUpdateTimeStamp,
    product,
    orderId,
    cruiseSummaryPrices,
}: OrderPricingSectionProps): JSX.Element => {
    return (
        <Card
            header={
                <div className={styles['order-pricing-summary__heading']}>
                    <Heading heading='2' size='2' onDarkBackground={true}>
                        {content.heading}
                    </Heading>
                    <div className={styles['order-pricing-summary__heading__cart-ref']}>
                        <Text color='orange' weight='bold'>
                            {content.orderRef}
                        </Text>
                        <Text color='white' weight='bold'>
                            {orderId}
                        </Text>
                    </div>
                </div>
            }
        >
            {product.productType === PRODUCT_TYPES.CRUISE && (
                <CruisePricingSection
                    lastPriceUpdateTimeStamp={lastPriceUpdateTimeStamp}
                    cruise={product as CruiseProduct}
                    cruiseSummaryPrices={cruiseSummaryPrices as CruiseSummaryPrices}
                />
            )}
            {/*{product.productType === PRODUCT_TYPES.FLIGHT && (*/}
            {/*    <FlightPricingSection flight={product as FlightProduct} />*/}
            {/*)}*/}
        </Card>
    )
}
export default OrderPricingSummary
