import { gql } from '@apollo/client'

export const CREATE_FLIGHT_ORDER = gql`
    mutation OrderCreateFlight(
        $flightId: String!
        $providerId: String!
        $providerItemId: String!
        $grossCost: Int!
    ) {
        orderCreate(
            items: [
                {
                    productType: "Flight"
                    attributes: {
                        Flight: {
                            id: $flightId
                            providerId: $providerId
                            providerItemId: $providerItemId
                            grossCost: $grossCost
                        }
                    }
                }
            ]
        ) {
            id
            status
            items {
                id
                product {
                    ... on OrderFlight {
                        id
                        name
                    }
                }
            }
        }
    }
`

const ORDER_ITEMS_QUERY = `
    items {
        id
        advisory {
            code
            message
        }
        bookedAt
        createdAt
        imported
        type
        passengersCriteria {
            passengers {
                travellerNumber
                pastPassengerReference
                military
                residency
                age
                assignedOptionalExtras {
                    code
                    description
                    displayAs
                    name
                    prices {
                        amount
                        currency
                        pricePer
                        rules {
                            dateRequired
                            maxAge
                            minAge
                            travellersAvail
                        }
                    }
                    subType
                    type
                }
                additionalBookingDetails {                
                    ... on FlightBookingDetail {
                        effectiveDate
                        detailType
                        flightType: type
                        totalFlightsPrice
                        itineraryItems {
                            arrivalDatetime
                            arrivalPort
                            cabinClass
                            carrier
                            departureDatetime
                            departurePort
                            flightNumber
                            seqNumber
                        }
                    }
                }
              }
        }
        product {
            ... on OrderFlight {
                id
                name
                airlineCode
                airlineName
                allowedIdentityDocumentTypes
                childAges
                conditions {
                    allowed
                    currencyCode
                    description
                    penaltyAmount
                    type
                }
                numberOfAdults
                nettCost
                numberOfChildren
                numberOfInfants
                passengerIdentityDocumentsRequired
                paymentRequiredBy
                price
                providerId
                providerItemId
                currencyCode
                fareType
                flightType
                instantPayment
                margin
                costBreakdown {
                    currencyCode
                    description
                    grossCost
                    margin
                    nettCost
                    quantity
                    type
                }
                flightLegs {
                    direction
                    duration
                    legNumber
                    segments {
                        arrivalAirport
                        arrivalAirportCode
                        arrivalDatetime
                        arrivalTerminal
                        baggageSummary
                        checkedBaggage
                        conditions {
                            allowed
                            currencyCode
                            description
                            penaltyAmount
                            type
                        }
                        departureAirport
                        departureAirportCode
                        departureDatetime
                        departureTerminal
                        duration
                        fareBrandName
                        flightNumber
                        marketingAirlineCode
                        marketingAirlineName
                        operatingAirlineCode
                        operatingAirlineName
                        segmentId
                        segmentNumber
                        stops
                        passengers {
                            seatClass
                            passengerId
                            fareBasis
                            cabinClassMarketingName
                            cabinClass
                            baggageAllowance {
                                quantity
                                type
                            }
                        }
                    }
                }
                selectedOptionalExtras {
                    type
                    segmentIds
                    quantityRequired
                    quantityAvailable
                    passengerIds
                    nettCost
                    margin
                    grossCost
                    description
                    currencyCode
                    code
                    appliesTo
                }
            }
            ... on CruiseSaleor {
                cabinGradeName
                cabinGradeCode
                cabinGradeDescription
                cabinNumber
                cruiseLineCode
                deckName
                deckLevel
                disembarkDate
                duration
                embarkDate
                id
                lineName
                name
                productName
                rateCode
                rateName
                rateDescription
                shipName
                itinerary {
                    arrivalTime
                    dayNumber
                    departureTime
                    geolocation {
                         centrePoint {
                            lat
                            lng
                        }
                    }
                    itemDate
                    itineraryItemType
                    portCode
                    portName
                }
                priceItems {
                    currency
                    commission
                    totalGrossPrice
                    totalTfpePrice
                    totalObcPrice
                    rateCode {
                        code
                        description
                        military
                        residency
                        refundPolicy
                      }
                    breakdownItems {
                        code
                        name
                        passengerNumber
                        price
                        fareType
                        itemType
                    }
                    paymentChoices {
                        paymentOption
                        paymentAmount
                        paymentCurrencyCode
                        dueDate
                    }
                    paymentScheduleItems {
                        amount
                        currency
                        description
                        dueDate
                        isDeposit
                        paymentType
                    }
                }
            }
        }
        numberOfTravellers
        leadTraveller
        assignedTravellers
        status
        paymentOption
        supplierOrderReference
    }
`

const ORDER_TRAVELERS_QUERY = `
    travellers {
        firstName
        gender
        lastName
        accessibilityNeeds
        dateOfBirth
        email
        middleName
        military
        militaryNumber
        nationality
        title
        travellerNumber
        pastPassengerReferences {
            pastPassengerReference
            supplierCode
        }
        address {
            cityRegion
            country
            line1
            line2
            stateProvince
            zipPostalCode
        }
        emergencyContact {
            email
            firstName
            lastName
            phoneNumber
        }
        passport {
            number
            endDate
            startDate
        }
        telephone {
            type
            number
        }
    }
`

export const GET_ORDER = gql`
    query GetOrder($orderId: String!) {
        order(id: $orderId) {
            id
            status
            contactDetails {
                title
                phoneNumber {
                    number
                    type
                }
                middleName
                lastName
                firstName
                email
                address {
                    cityRegion
                    country
                    line1
                    line2
                    stateProvince
                    zipPostalCode
                }
            }
            ${ORDER_ITEMS_QUERY}            
            ${ORDER_TRAVELERS_QUERY} 
        }
    }
`

export const GET_OPTIONAL_EXTRAS = gql`
    query GetOptionalExtras(
        $id: String!
        $supplierCode: String!
        $rateCode: String!
        $cabinGradeCode: String!
        $cabinNumber: String!
        $passengers: Passengers!
    ) {
        optionalExtras(
            id: $id
            supplierCode: $supplierCode
            passengers: $passengers
            rateCode: $rateCode
            cabinGradeCode: $cabinGradeCode
            cabinNumber: $cabinNumber
        ) {
            optionalExtras {
                code
                description
                displayAs
                name
                prices {
                    amount
                    currency
                    pricePer
                    rules {
                        dateRequired
                        maxAge
                        minAge
                        travellersAvail
                    }
                }
                subType
                type
            }
        }
    }
`

export const UPDATE_ORDER_OPTIONAL_EXTRAS = gql`
    mutation AddOptionalExtrasCruise($id: String!, $itemId: String!, $optionalExtraCodes: [String!]!) {
        orderOptionalExtrasSet(id: $id, itemId: $itemId, optionalExtraCodes: $optionalExtraCodes) {
            id
            ${ORDER_ITEMS_QUERY}
            ${ORDER_TRAVELERS_QUERY}
            contactDetails {
                title
                phoneNumber {
                    number
                    type
                }
                middleName
                lastName
                firstName
                email
                address {
                    cityRegion
                    country
                    line1
                    line2
                    stateProvince
                    zipPostalCode
                }
            }
        }
        
    }
`

export const ORDER_TRAVELLER_ADD = gql`
    mutation OrderTravellerAdd(
        $orderId: String!
        $travellerNumber: Int!
        $title: Title
        $firstName: String
        $middleName: String
        $lastName: String
        $dateOfBirth: Date
        $nationality: String
        $accessibilityNeeds: Boolean
        $passport: PassportInput
        $emergencyContact: EmergencyContactInput
        $pastPassengerReferences: [PastPassengerInput!]
    ) {
        orderTravellerAdd(
            id: $orderId
            traveller: {
                travellerNumber: $travellerNumber
                title: $title
                firstName: $firstName
                middleName: $middleName
                lastName: $lastName
                dateOfBirth: $dateOfBirth
                nationality: $nationality
                accessibilityNeeds: $accessibilityNeeds
                passport: $passport
                pastPassengerReferences: $pastPassengerReferences
                emergencyContact: $emergencyContact
            }
        ) {
            id
        }
    }
`

export const ORDER_TRAVELLER_UPDATE = gql`
    mutation OrderTravellerUpdate(
        $orderId: String!
        $travellerNumber: Int!
        $title: Title
        $firstName: String
        $middleName: String
        $lastName: String
        $dateOfBirth: Date
        $email: String
        $nationality: String
        $military: Boolean
        $militaryNumber: String
        $accessibilityNeeds: Boolean
        $passport: PassportInput
        $emergencyContact: EmergencyContactInput
        $pastPassengerReferences: [PastPassengerInput!]
    ) {
        orderTravellerUpdate(
            id: $orderId
            travellerNumber: $travellerNumber
            traveller: {
                travellerNumber: $travellerNumber
                title: $title
                firstName: $firstName
                middleName: $middleName
                lastName: $lastName
                dateOfBirth: $dateOfBirth
                nationality: $nationality
                accessibilityNeeds: $accessibilityNeeds
                email: $email
                military: $military
                militaryNumber: $militaryNumber
                passport: $passport
                pastPassengerReferences: $pastPassengerReferences
                emergencyContact: $emergencyContact
            }
        ) {
            id
        }
    }
`

export const ORDER_ITEM_TRAVELLERS_ASSIGN = gql`
    mutation OrderItemTravellerAssign(
        $orderId: String!
        $orderItemId: String!
        $travellerNumbers: [Int!]!
    ) {
        orderItemTravellerAssign(
            id: $orderId
            itemId: $orderItemId
            travellerNumbers: $travellerNumbers
        ) {
            id
        }
    }
`

export const ORDER_ITEM_LEAD_TRAVELLER_ASSIGN = gql`
    mutation OrderItemLeadTravellerAssign(
        $orderId: String!
        $orderItemId: String!
        $leadTravellerNumber: Int!
    ) {
        orderItemLeadTravellerAssign(
            id: $orderId
            itemId: $orderItemId
            leadTravellerNumber: $leadTravellerNumber
        ) {
            id
        }
    }
`

export const ORDER_CONTACT_DETAILS_UPDATE = gql`
    mutation OrderContactDetailsUpdate(
        $orderId: String!
        $addressLine1: String!
        $addressLine2: String!
        $cityRegion: String!
        $zipPostalCode: String!
        $stateProvince: String
        $country: String!
        $email: String
        $firstName: String
        $lastName: String
        $middleName: String
        $title: Title
        $phoneType: String!
        $phoneNumber: String!
    ) {
        orderContactDetailsUpdate(
            id: $orderId
            contactDetails: {
                address: {
                    line1: $addressLine1
                    line2: $addressLine2
                    cityRegion: $cityRegion
                    zipPostalCode: $zipPostalCode
                    stateProvince: $stateProvince
                    country: $country
                }
                email: $email
                firstName: $firstName
                lastName: $lastName
                middleName: $middleName
                title: $title
                phoneNumber: { type: $phoneType, number: $phoneNumber }
            }
        ) {
            id
        }
    }
`

export const RETRIEVE_BOOKING_ORDER = gql`
    query RetrieveBooking (
        $bookingId: String!
        $supplierCode: String!
    ) {
        retrieveBooking(
            bookingId: $bookingId
            supplierCode: $supplierCode
        ) {
            booking {
                id
                status
                contactDetails {
                    title
                    phoneNumber {
                        number
                        type
                    }
                    middleName
                    lastName
                    firstName
                    email
                    address {
                        cityRegion
                        country
                        line1
                        line2
                        stateProvince
                        zipPostalCode
                    }
                }
                ${ORDER_ITEMS_QUERY}            
                ${ORDER_TRAVELERS_QUERY} 
            }
        }
    }
`

export const IMPORT_BOOKING_ORDER = gql`
    mutation ImportBooking($bookingId: String!, $supplierCode: String!) {
        importBooking(bookingId: $bookingId, supplierCode: $supplierCode) {
            id
            status
        }
    }
`
