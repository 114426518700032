import React from 'react'
import HtmlComment from 'utils/trams-rescard/HtmlComment/HtmlComment'
import {
    CruiseProduct,
    OrderContent,
    OrderItemStatus,
    PRODUCT_TYPES,
    Traveller,
} from 'api-data-models/order/OrderContentModel'
import { create } from 'xmlbuilder'
import { format } from 'date-fns'
import { getFormattedDate } from 'utils/date-helpers'

export const PASSENGER_TYPES: {
    ADULT: 'A'
    CHILD: 'C'
    INFANT: 'I'
} = {
    ADULT: 'A',
    CHILD: 'C',
    INFANT: 'I',
}

type PassengerType = (typeof PASSENGER_TYPES)[keyof typeof PASSENGER_TYPES]

type Passenger = {
    LASTNAME: string
    FIRSTNAME: string
    MIDDLENAME?: string
    PASSENGERTYPE: PassengerType
}

export const EMBARKATION_TYPES: {
    ARRIVAL: 'Arrival'
    DEPARTURE: 'Departure'
} = {
    ARRIVAL: 'Arrival',
    DEPARTURE: 'Departure',
}

type EmbarkationType = (typeof EMBARKATION_TYPES)[keyof typeof EMBARKATION_TYPES]

type DepartureItinerary = {
    DEPARTCITYCODE: string
    DEPARTCITYNAME: string | null
    DEPARTDATE: string
    DEPARTTIME: string
    DESCRIPTION: EmbarkationType
}
type ArrivalItinerary = {
    ARRIVECITYCODE: string
    ARRIVECITYNAME: string | null
    ARRIVEDATE: string
    ARRIVETIME: string
    DESCRIPTION: EmbarkationType
}

export const RESERVATION_TYPES: {
    AIR: 0
    HOTEL: 1
    CAR: 2
    CRUISE: 3
    INSURANCE: 4
    MISCELLANEOUS: 5
    RAIL: 6
    TOUR: 8
    TRANSPORTATION: 9
    UNKNOWN: 10
} = {
    AIR: 0,
    HOTEL: 1,
    CAR: 2,
    CRUISE: 3,
    INSURANCE: 4,
    MISCELLANEOUS: 5,
    RAIL: 6,
    TOUR: 8,
    TRANSPORTATION: 9,
    UNKNOWN: 10,
}

type ReservationType = (typeof RESERVATION_TYPES)[keyof typeof RESERVATION_TYPES]

/** Type SERVICEPROVIDER has the following fields:
 * PROVIDERTYPE: numbered 0-9 for different types, e.e.g air, cruise, hotel etc, 10 was added for possible errors (//TODO: check as this may not be needed)
 * SERVICEPROVIDERNAME: this is the name of the cruise ship, hotel or other service.
 * STARTDATE: date when trip starts
 * ENDDATE: date when trip ends
 * CATEGORY: (desired) Used in the following Travel Categories: Hotel: Room Type Car: Car Category Cruise: Cabin Category Tour: Tour Name Transportation : Type (Bus, Van, Limo, etc)
 * SHIPDECK: (desired): name of the Deck
 * CABINROOM (not required but desired): Number of Cabin.
 * ITINERARYLIST: included ONLY for Cruises - list of Itinerary items (in order of days, switching between departure and arrival)
 * SPPASSENGERLIST: PASSENGER[]
 **/

type ServiceProvider = {
    PROVIDERTYPE: ReservationType
    SERVICEPROVIDERNAME: string
    STARTDATE: string
    ENDDATE: string
    CATEGORY?: string
    SHIPDECK?: string
    CABINROOM: string
    ITINERARYLIST: (DepartureItinerary | ArrivalItinerary)[] //tuple because it enforces the alternation of these types within the list
    SPPASSENGERLIST: Passenger[]
}

/** type RESERVATION:
 * VENDORCODE: Code for the vendor. We can assign one if the vendor does not have one that they use. This is used to find a match on the vendor profile in CBPlus. For backward compatibility. Vendor information is on the Vendor node. //TODO: ask if this is our supplierCode (if that's ok/enough?)
 * VENDORNAME: Name of the vendor or the booking site. //TODO: is this suppliers or us?
 * RESTYPE: 0 = Air 1 = Hotel 2 = Car 3 = Cruise 4 = Insurance 5 = Miscellaneous 6 = Rail 7 = Tour 8 = Service Fee 9 = Transportation TODO:(added 10 for error? is that ok?)
 * RESDATE: Date of reservation in format: MMDDYYYY
 * CONFIRMATIONNUMBER: (required) Reference number assigned to the reservation - TODO: which confirmation number is this/where do i get this? - booking reference - STUB or not
 * RATECODE: (optional) rate code
 * TOTALBASE: (desired) Base amount of the reservation
 * TOTALTAX: (optional) Tax amount of the reservation
 * TOTALCOMMISSION: (desired) Commission amount of the reservation
 * SOURCEOFBOOKING: (desired) Name of the site where the reservation is made.This is useful if there is more than one site that the agent can book with a particular vendor. //TODO: should we hard code this to be 'Traveltek then'?
 * BOOKINGSTATUS: (desired) This only has 3 valid values: Confirmed Quoted Cancelled; The difference between this and the STATUS field is that this affects the Reservation Totals (Reservation, Invoiced, Non-Invoiced Amounts). Note:If you use Quote, ClientBase will not allow an Invoice to be priced, but will show the amounts in an Itinerary. Defaults to Confirmed if not specified
 * STATUS: (desired): Status of the reservation. This is where the status of the booking within the supplier system should be stored. Use any Item description you wish: Under Deposit, Paid in Full, On Hold, On Request, (Free Flow).
 * STARTDATE: string | null
 * ENDDATE: string | null
 * DURATION: number
 * NOOFPAX: number | undefined
 * DEPOSITDUEDATE: Date
 * FINALPYMNTDUEDATE: string
 * INVOICEREMARKS: string | null
 * //TODO: the last three (below) are not in the table in the docs so unsure what to do with them
 * ITEMAMOUNTLIST: ITEMAMOUNT[] By not including this section, the amounts in the Reservation Totals section will be used.
 * PASSENGERLIST: PASSENGER[]
 * SERVICEPROVIDERLIST: SERVICEPROVIDER[]
 **/

type Reservation = {
    VENDORCODE: string
    VENDORNAME: string
    RESTYPE: ReservationType
    RESDATE: string
    CONFIRMATIONNUMBER: string
    RATECODE?: string
    TOTALBASE?: number
    TOTALTAX?: number
    TOTALCOMMISSION?: number
    SOURCEOFBOOKING?: string
    BOOKINGSTATUS?: string
    STATUS?: string
    STARTDATE?: string
    ENDDATE?: string
    DURATION?: number
    NOOFPAX?: number
    DEPOSITDUEDATE?: string
    FINALPYMNTDUEDATE?: string
    PASSENGERLIST: Passenger[]
    SERVICEPROVIDERLIST: ServiceProvider[]
}

/** the Rescard type has the following fields:
 * AGENTREMARKS (optional): //I assumed this because there's nothing in the docs TODO - ask about this
 * DATEFORMAT (optional): as above - assumed - a string representing what format the date will be in, and the options are: MMDDYYYY or MMDDYYYY
 * RESERVATIONLIST: a list of all reservations
 * **/
//TODO: diagram shows the two fields directly under Rescard are PassengerList and Reservationlist but the example had the structure below
type RescardTemplateType = {
    RESCARD: {
        AGENTREMARKS?: string
        DATEFORMAT: string
        RESERVATIONLIST: Reservation[]
    }
}

export function calculateAgeOnDisembarkDate({
    dateOfBirth,
    disembarkDate,
}: {
    dateOfBirth: string
    disembarkDate: string
}): number {
    const birthDateObj = new Date(dateOfBirth)
    const returnDateDateObj = new Date(disembarkDate)

    let age = returnDateDateObj.getFullYear() - birthDateObj.getFullYear()

    // Check if the birthday has occurred this year, and subtract one if not
    if (
        returnDateDateObj.getMonth() < birthDateObj.getMonth() ||
        (returnDateDateObj.getMonth() === birthDateObj.getMonth() &&
            returnDateDateObj.getDate() < birthDateObj.getDate())
    ) {
        age--
    }
    return age
}
export function getPassengerType(age: number): PassengerType {
    let passengerType: PassengerType = PASSENGER_TYPES.ADULT
    if (age >= 0 && age <= 1) {
        passengerType = PASSENGER_TYPES.ADULT
    } else if (age >= 2 && age <= 20) {
        passengerType = PASSENGER_TYPES.CHILD
    }
    return passengerType
}
export function getItineraryList({
    cruiseProduct,
}: {
    cruiseProduct: CruiseProduct
}): (DepartureItinerary | ArrivalItinerary)[] {
    const itineraryList: (DepartureItinerary | ArrivalItinerary)[] = []

    const allItinerary = cruiseProduct.itinerary.portCodesWithNamesAndDaysAndTimes

    const onlyPortItinerary = allItinerary.filter((item) => {
        return (
            item.itemType === 'DISEMBARK' || item.itemType === 'EMBARK' || item.itemType === 'PORT'
        )
    })

    for (const item of onlyPortItinerary) {
        if (item.arrivalTime) {
            const arrivalItineraryItem = {
                ARRIVECITYCODE: item.portCode as string, // if arrivalTime then has portCode (needed because AT SEA has null for portCode for some suppliers)
                ARRIVECITYNAME: item.portName,
                ARRIVEDATE: getFormattedDate(
                    new Date(item.itemDate).toISOString(),
                    'short'
                ).replace(/\//g, ''),
                ARRIVETIME: item.arrivalTime.replace(/:/g, '').slice(0, 4),
                DESCRIPTION: EMBARKATION_TYPES.ARRIVAL,
            }
            itineraryList.push(arrivalItineraryItem)
        }
        if (item.departureTime) {
            const departureItineraryItem: DepartureItinerary = {
                DEPARTCITYCODE: item.portCode as string,
                DEPARTCITYNAME: item.portName,
                DEPARTDATE: getFormattedDate(
                    new Date(item.itemDate).toISOString(),
                    'short'
                ).replace(/\//g, ''),
                DEPARTTIME: item.departureTime.replace(/:/g, '').slice(0, 4),
                DESCRIPTION: EMBARKATION_TYPES.DEPARTURE,
            }
            itineraryList.push(departureItineraryItem)
        }
    }
    return itineraryList
}
export function getReservationType(reservation: string): ReservationType {
    let providerType: ReservationType
    switch (reservation) {
        case PRODUCT_TYPES.CRUISE:
            providerType = RESERVATION_TYPES.CRUISE
            break
        case PRODUCT_TYPES.FLIGHT:
            providerType = RESERVATION_TYPES.AIR
            break
        default:
            providerType = RESERVATION_TYPES.CRUISE
    }
    return providerType
}

export function getPassengerList({
    assignedTravellersData,
    disembarkDate,
}: {
    assignedTravellersData: Traveller[]
    disembarkDate: string
}): Passenger[] {
    const passengerList: Passenger[] = []
    for (const traveller of Object.values(assignedTravellersData)) {
        const lastName = traveller.lastName
        const firstName = traveller.firstName
        const middleName = traveller.middleName
        const dateOfBirth = traveller.dateOfBirth
        const passenger: Passenger = {
            LASTNAME: lastName,
            FIRSTNAME: firstName,
            MIDDLENAME: middleName,
            PASSENGERTYPE: getPassengerType(
                calculateAgeOnDisembarkDate({ dateOfBirth, disembarkDate })
            ),
        }
        passengerList.push(passenger)
    }
    return passengerList
}

export function getServiceProviderList({
    cruiseProduct,
    assignedTravellersData,
}: {
    cruiseProduct: CruiseProduct
    assignedTravellersData: Traveller[]
}): ServiceProvider[] {
    const serviceProviderList: ServiceProvider[] = []
    const serviceProviderItem = {
        PROVIDERTYPE: getReservationType(cruiseProduct.productType),
        SERVICEPROVIDERNAME: cruiseProduct.shipName,
        STARTDATE: getFormattedDate(
            new Date(cruiseProduct.embarkDate).toISOString(),
            'short'
        ).replace(/\//g, ''),
        ENDDATE: getFormattedDate(
            new Date(cruiseProduct.disembarkDate).toISOString(),
            'short'
        ).replace(/\//g, ''),
        CATEGORY: cruiseProduct.cruiseName,
        SHIPDECK: cruiseProduct.deckName,
        CABINROOM: cruiseProduct.cabinNumber,
        ITINERARYLIST: getItineraryList({ cruiseProduct }),
        SPPASSENGERLIST: getPassengerList({
            assignedTravellersData: assignedTravellersData,
            disembarkDate: cruiseProduct.disembarkDate,
        }),
    }
    serviceProviderList.push(serviceProviderItem)
    return serviceProviderList
}

export function getReservationList({
    cruiseProduct,
    disembarkDate,
    assignedTravellersData,
    orderBookedDate,
    supplierOrderItemReference,
    orderItemStatus,
    numberOfTravellers,
    pricingData,
}: {
    cruiseProduct: CruiseProduct
    disembarkDate: string
    assignedTravellersData: Traveller[]
    orderBookedDate: string
    supplierOrderItemReference: string
    orderItemStatus: OrderItemStatus
    numberOfTravellers: number
    travelCategory: ReservationType
    pricingData: Record<string, any>
}): Reservation[] {
    const reservationList: Reservation[] = []
    const cruiseReservationItem = {
        VENDORCODE: cruiseProduct.supplierCode,
        VENDORNAME: cruiseProduct.lineName,
        RESTYPE: RESERVATION_TYPES.CRUISE,
        RESDATE: orderBookedDate,
        CONFIRMATIONNUMBER: supplierOrderItemReference,
        RATECODE: cruiseProduct.rateCode,
        TOTALBASE:
            parseInt(pricingData.totalGrossPrice.replace(/\./g, '')) -
            parseInt(pricingData.totalTfpePrice.replace(/\./g, '')),
        TOTALTAX: parseInt(pricingData.totalTfpePrice.replace(/\./g, '')),
        TOTALCOMMISSION: parseInt(pricingData.commission.replace(/\./g, '')),
        SOURCEOFBOOKING: 'Traveltek-AgentConnect',
        //TODO: BOOKINGSTATUS:needs to be updated on the api BE as it's using orderitemstatus, then we can pass the value instead of letting it default on CBO
        BOOKINGSTATUS: orderItemStatus,
        STATUS: orderItemStatus,
        STARTDATE: getFormattedDate(
            new Date(cruiseProduct.embarkDate).toISOString(),
            'short'
        ).replace(/\//g, ''),
        ENDDATE: getFormattedDate(
            new Date(cruiseProduct.disembarkDate).toISOString(),
            'short'
        ).replace(/\//g, ''),
        DURATION: cruiseProduct.duration,
        NOOFPAX: numberOfTravellers,
        // TODO: DEPOSITDUEDATE: Drive using paymentOption from API,
        PASSENGERLIST: getPassengerList({ disembarkDate, assignedTravellersData }),
        SERVICEPROVIDERLIST: getServiceProviderList({ cruiseProduct, assignedTravellersData }),
    }
    reservationList.push(cruiseReservationItem)
    return reservationList
}
export function transformCruiseOrderToRescardShape({
    cruiseOrder,
}: {
    cruiseOrder: OrderContent
}): RescardTemplateType {
    const orderBookedDate = format(new Date(cruiseOrder.orderItem.bookedAt), 'MMddyyyy')
    const orderTravellers = cruiseOrder.travellers
    const supplierOrderItemReference = cruiseOrder.orderItem.supplierOrderItemReference as string
    const cruiseProduct = cruiseOrder.orderItem.product as CruiseProduct
    const pricingData = cruiseProduct.pricing

    const orderItemStatus = cruiseOrder.orderItem.orderItemStatus
    const numberOfTravellers = cruiseOrder.orderItem.numberOfTravellers
    const assignedTravellerNumbers = cruiseOrder.orderItem.assignedTravellers

    const disembarkDate = cruiseProduct.disembarkDate

    const assignedTravellersData: Traveller[] = assignedTravellerNumbers.map((travelerNumber) => {
        return orderTravellers[travelerNumber]
    })

    const travelCategory = 3 //3 for cruise

    const reservationList = getReservationList({
        cruiseProduct,
        disembarkDate,
        assignedTravellersData,
        orderBookedDate,
        supplierOrderItemReference,
        orderItemStatus,
        numberOfTravellers,
        travelCategory,
        pricingData,
    })

    return {
        RESCARD: {
            AGENTREMARKS: 'Booked on Traveltek AgentConnect',
            DATEFORMAT: 'MMDDYYYY', //TODO: Options are 'MMddyyyy' or 'DDmmyyyy' - check if our dates are in one of these formats
            // use our Order as values to populate the RESCARD keys.. this could get be tricky, but do type first to help?
            RESERVATIONLIST: reservationList,
        },
    }
}

export function getRescardHtmlComment({ order }: { order: OrderContent }): JSX.Element | null {
    if (order.orderItem.product?.productType !== PRODUCT_TYPES.CRUISE) return null
    const RescardShapeOrder = transformCruiseOrderToRescardShape({ cruiseOrder: order })

    // Create an XML builder for RESCARD
    const xmlBuilder = create('RESCARD')

    // Add AGENTREMARKS and DATEFORMAT to the XML
    xmlBuilder.ele('AGENTREMARKS', RescardShapeOrder.RESCARD.AGENTREMARKS ?? '')
    xmlBuilder.ele('DATEFORMAT', RescardShapeOrder.RESCARD.DATEFORMAT)

    // Create a RESERVATIONLIST element
    const reservationList = xmlBuilder.ele('RESERVATIONLIST')

    // Add RESERVATION elements to the RESERVATIONLIST
    RescardShapeOrder.RESCARD.RESERVATIONLIST.forEach((reservation) => {
        const reservationElement = reservationList.ele('RESERVATION')

        // Add reservation properties to the reservation element
        reservationElement.ele('VENDORCODE', reservation.VENDORCODE)
        reservationElement.ele('VENDORNAME', reservation.VENDORNAME)
        reservationElement.ele('RESTYPE', reservation.RESTYPE)
        reservationElement.ele('RESDATE', reservation.RESDATE)
        reservationElement.ele('CONFIRMATIONNUMBER', reservation.CONFIRMATIONNUMBER)
        reservationElement.ele('RATECODE', reservation.RATECODE)
        reservationElement.ele('TOTALBASE', reservation.TOTALBASE)
        reservationElement.ele('TOTALTAX', reservation.TOTALTAX)
        reservationElement.ele('TOTALCOMMISSION', reservation.TOTALCOMMISSION)
        reservationElement.ele('SOURCEOFBOOKING', reservation.SOURCEOFBOOKING)
        reservationElement.ele('STATUS', reservation.STATUS)
        reservationElement.ele('STARTDATE', reservation.STARTDATE)
        reservationElement.ele('ENDDATE', reservation.ENDDATE)

        // Add PASSENGERLIST
        const passengerList = reservationElement.ele('PASSENGERLIST')

        // Add PASSENGER elements to PASSENGERLIST
        reservation.PASSENGERLIST.forEach((passenger) => {
            const passengerElement = passengerList.ele('PASSENGER')
            passengerElement.ele('LASTNAME', passenger.LASTNAME)
            passengerElement.ele('FIRSTNAME', passenger.FIRSTNAME)
            passengerElement.ele('PASSENGERTYPE', passenger.PASSENGERTYPE)
        })

        // Add SERVICEPROVIDERLIST
        const serviceProviderList = reservationElement.ele('SERVICEPROVIDERLIST')

        // Add SERVICEPROVIDER elements to SERVICEPROVIDERLIST
        reservation.SERVICEPROVIDERLIST.forEach((serviceProvider) => {
            const serviceProviderElement = serviceProviderList.ele('SERVICEPROVIDER')
            // Add serviceProvider properties to the serviceProvider element
            serviceProviderElement.ele('PROVIDERTYPE', serviceProvider.PROVIDERTYPE)
            serviceProviderElement.ele('SERVICEPROVIDERNAME', serviceProvider.SERVICEPROVIDERNAME)
            serviceProviderElement.ele('STARTDATE', serviceProvider.STARTDATE)
            serviceProviderElement.ele('ENDDATE', serviceProvider.ENDDATE)
            serviceProviderElement.ele('CATEGORY', serviceProvider.CATEGORY)
            serviceProviderElement.ele('SHIPDECK', serviceProvider.SHIPDECK)
            serviceProviderElement.ele('CABINROOM', serviceProvider.CABINROOM)

            const itineraryListElement = serviceProviderElement.ele('ITINERARYLIST')

            const itineraryList = serviceProvider.ITINERARYLIST

            itineraryList.forEach((itineraryItem) => {
                const itineraryElement = itineraryListElement.ele('ITINERARY')

                // Add itinerary properties to the itinerary element
                if ('ARRIVECITYCODE' in itineraryItem) {
                    const arriveItinerary = itineraryItem
                    itineraryElement.ele('ARRIVECITYCODE', arriveItinerary.ARRIVECITYCODE)
                    itineraryElement.ele('ARRIVECITYNAME', arriveItinerary.ARRIVECITYNAME ?? '')
                    itineraryElement.ele('ARRIVEDATE', arriveItinerary.ARRIVEDATE)
                    itineraryElement.ele('ARRIVETIME', arriveItinerary.ARRIVETIME)
                    itineraryElement.ele('DESCRIPTION', arriveItinerary.DESCRIPTION)
                } else {
                    const departItinerary = itineraryItem
                    itineraryElement.ele('DEPARTCITYCODE', departItinerary.DEPARTCITYCODE)
                    itineraryElement.ele('DEPARTCITYNAME', departItinerary.DEPARTCITYNAME ?? '')
                    itineraryElement.ele('DEPARTDATE', departItinerary.DEPARTDATE)
                    itineraryElement.ele('DEPARTTIME', departItinerary.DEPARTTIME)
                    itineraryElement.ele('DESCRIPTION', departItinerary.DESCRIPTION)
                }
            })
        })
    })
    // Convert the XML builder to a string
    const xmlOrder = xmlBuilder.end({ pretty: true })
    return <HtmlComment text={xmlOrder} />
}

export default getRescardHtmlComment
