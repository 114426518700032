import React, { FC, useState } from 'react'
import { CruiseProduct, OrderContent, PRODUCT_TYPES } from 'api-data-models/order/OrderContentModel'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import styles from './OrderPreviewLayout.module.scss'
import Heading from 'components/basics/Heading/Heading'
import Button from 'components/basics/Button/Button'
import allContent from 'content/content'
import { useNavigate } from 'react-router-dom'
import OrderPreviewCard from './OrderPreviewCard/OrderPreviewCard'
import * as self from './OrderPreviewLayout'
import { ROUTES } from 'components/sections/app/AppRoutes'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import callApi from 'services/callApi/callApi.service'
import { orderServiceUrls } from 'utils/order-service-urls'
import { HTTP_METHODS } from 'utils/constants'
import { getRestErrorMessage } from 'utils/api-errors-helpers/get-rest-error-message'

const alertsContent = allContent.order.orderPreviewPage.alerts
const buttonsContent = allContent.order.orderPreviewPage.buttons

export const submitBookingImport = ({
    variables,
    setIsSubmitting,
    onSuccess,
    onError,
}: {
    variables: { bookingId: string; supplierCode: string }
    setIsSubmitting: (value: React.SetStateAction<any>) => void
    onSuccess: (values: any) => void
    onError: (error: any) => void
}): void => {
    const requestUrl = orderServiceUrls.importOrder(variables.bookingId, variables.supplierCode)
    setIsSubmitting(true)
    callApi({
        url: requestUrl,
        method: HTTP_METHODS.POST,
    })
        .then((response) => response.json())
        .then((data) => {
            if (data?.id) onSuccess({ id: data.id })
        })
        .catch((error: any) => {
            const parsedError = getRestErrorMessage({
                errors: error.cause,
                source: requestUrl,
            })
            onError(parsedError)
        })
        .finally(() => setIsSubmitting(false))
}

export interface OrderPreviewLayoutProps {
    orderState: OrderContent
    queryVariables: { bookingId: string; supplierCode: string }
}

const OrderPreviewLayout: FC<OrderPreviewLayoutProps> = ({ orderState, queryVariables }) => {
    const orderItem = orderState.orderItem
    const productType = orderItem.product?.productType
    const productIsCruise = productType === PRODUCT_TYPES.CRUISE
    const cruiseProduct = orderItem.product as CruiseProduct
    const supplierOrderItemReference = orderItem.supplierOrderItemReference

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState<any>(null)

    const navigate = useNavigate()
    const onGoBack = (): void => navigate(-1)

    const handleSubmitRequest = (): void => {
        self.submitBookingImport({
            variables: queryVariables,
            setIsSubmitting,
            onSuccess: (orderData): void => {
                if (orderData) {
                    navigate(`${ROUTES.ORDER_IMPORT}/?orderId=${orderData.id}`)
                }
            },
            onError: (error): void => {
                setError(error)
            },
        })
    }

    return (
        <div className={styles.container}>
            <Heading heading='1'>{'Pre-import Booking'}</Heading>
            {productIsCruise ? (
                <>
                    <InfoBanner
                        text={alertsContent.previewAlertMessage}
                        bannerType='info'
                        logType='info'
                        id={'preview-alert'}
                    />
                    {error && <ErrorList errorsList={error} source='ImportBooking' />}
                    <OrderPreviewCard
                        cruiseProduct={cruiseProduct}
                        numberOfPassengers={orderState.orderItem.numberOfTravellers}
                        supplierOrderItemReference={supplierOrderItemReference}
                    />
                    <div className={styles.footer}>
                        <div className={styles.footer__actions}>
                            <Button
                                text={buttonsContent.cancel}
                                disabled={isSubmitting}
                                onClick={onGoBack}
                                flavour='tertiary'
                                type='button'
                            />
                            <Button
                                text={buttonsContent.import}
                                disabled={isSubmitting}
                                showSpinner={isSubmitting}
                                type='button'
                                onClick={(): void => {
                                    handleSubmitRequest()
                                }}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <InfoBanner
                    text={alertsContent.previewNotSupportedMessage}
                    bannerType='error'
                    logType='warn'
                    id={'preview-warning-alert'}
                />
            )}
        </div>
    )
}

export default OrderPreviewLayout
