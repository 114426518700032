import React, { useState } from 'react'
import Accordion from 'components/blocks/Accordion/Accordion/Accordion'
import DescriptionListDisplay from 'components/blocks/DescriptionListDisplay/DescriptionListDisplay'
import Button from 'components/basics/Button/Button'
import Modal from 'components/blocks/Modal/Modal'
import PriceBreakdownView from '../PriceBreakdownView/PriceBreakdownView'
import { CabinContent } from 'components/../api-data-models/CabinContentModel'
import styles from './CabinInfoSection.module.css'
import allContent from 'content/content'

const content = allContent.cruise.cabinPage

type CabinInfoSectionProps = {
    cabinData: CabinContent
}

const CabinInfoSection: React.FC<CabinInfoSectionProps> = ({ cabinData }) => {
    const [pricingModalOpen, setPricingModalOpen] = useState(false)
    const {
        cruiseId,
        embarkDate,
        disembarkDate,
        duration,
        nights,
        supplierName,
        shipName,
        cabinGrade,
    } = content.informationSection.informationKeys

    const informationDataListLeft = (
        <DescriptionListDisplay
            className={styles.columns}
            isAlternatingColor
            keyValueContent={[
                [cruiseId, cabinData.cruiseId],
                [embarkDate, cabinData.embarkDate],
                [disembarkDate, cabinData.disembarkDate],
                [duration, `${cabinData.duration} ${nights}`],
            ]}
        />
    )
    const informationDataListRight = (
        <DescriptionListDisplay
            className={styles.columns}
            isAlternatingColor
            keyValueContent={[
                [
                    cabinGrade,
                    `${cabinData.cabinGrade.code}, ${cabinData.cabinGrade.name} (${cabinData.cabinGrade.cabinType})`,
                ],
                [supplierName, cabinData.supplierName],
                [shipName, cabinData.shipName],
            ]}
        />
    )

    return (
        <>
            <Accordion
                baseId='info-section'
                titleSize={'3'}
                title={content.informationSection.heading}
                darkHeading={true}
                isPadded={true}
                startOpen={true}
            >
                <div className={styles.container}>
                    {informationDataListLeft}
                    {informationDataListRight}
                </div>
                <Button
                    className={styles.button}
                    id='pricing-info-button'
                    text={content.informationSection.pricingButton}
                    onClick={(): void => setPricingModalOpen(true)}
                    flavour='secondary'
                />
                <Modal
                    headerText={content.informationSection.pricingTable.heading}
                    isOpen={pricingModalOpen}
                    returnFocusId='view-pricing-modal'
                    setClosed={(): void => setPricingModalOpen(false)}
                >
                    <PriceBreakdownView
                        commission={cabinData.commission}
                        breakdownPerPassenger={cabinData.breakdownPerPassenger}
                        currency={cabinData.currency}
                        totalGrossPrice={cabinData.totalGrossPrice}
                        cabinGradeCode={cabinData.cabinGrade.code}
                        rateCode={cabinData.rateCode}
                        cabinGradeDescription={cabinData?.cabinGrade?.description}
                        rateCodeDescription={cabinData?.rateCodes[0].description}
                        priceProps={{
                            military: cabinData?.rateCodes[0]?.military,
                            residency: cabinData?.rateCodes[0]?.residency,
                            refundPolicy: cabinData?.rateCodes[0]?.refundPolicy,
                            onBoardCredits: cabinData.totalOnboardCreditPrice,
                        }}
                    />
                </Modal>
            </Accordion>
        </>
    )
}

export default CabinInfoSection
