import React from 'react'

import SailingLayout from 'components/layouts/cruise/SailingLayout/SailingLayout'

/** SailingPage: returns either a component that render the details of a sailing, or false */
const SailingPage: React.FC = (): JSX.Element => {
    return <SailingLayout />
}

export default SailingPage
