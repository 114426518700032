import React, { forwardRef, FC } from 'react'
import classnames from 'classnames'

import Text, { TextColours } from 'components/basics/Text/Text'
import Tooltip from 'components/basics/Tooltip/Tooltip'
import styles from './LabelledInput.module.css'

type LabelProps = {
    htmlFor?: string
    className?: string
    disabled?: boolean
    required?: boolean
    labelColor?: TextColours
    children: string | React.ReactNode
}
export const Label: FC<LabelProps> = ({
    htmlFor,
    className,
    disabled,
    required,
    labelColor = 'primary-blue',
    children,
}) => {
    const RedAsterisk = <span className={styles.asterisk}>*</span>

    return (
        <label htmlFor={htmlFor} className={className}>
            <Text size='S' color={disabled ? 'disabled' : labelColor}>
                {children}
                {required && RedAsterisk}
            </Text>
        </label>
    )
}

export const GroupLabel: FC<LabelProps> = ({
    className,
    disabled,
    required,
    labelColor = 'primary-blue',
    children,
}) => {
    const RedAsterisk = <span className={styles.asterisk}>*</span>

    return (
        <Text color={disabled ? 'disabled' : labelColor} className={className}>
            {children}
            {required && RedAsterisk}
        </Text>
    )
}

type LabelledInputProps = {
    htmlFor: string | undefined
    /** this allows the label to pass the htmlFor as id to the corresponding input field (id on TextInput is therefore not mandatory, as it can be passed automatically)*/
    label: string
    /** toggle for error styling to pass to child inputs */
    isErrored?: boolean
    /** when label is not wanted visually, we can hide it with this prop */
    labelHidden?: boolean
    labelColor?: TextColours
    /** show an icon, maybe even provide the name of icon to show? */
    children: React.ReactElement
    disabled?: boolean
    className?: string
    /** toggle to show red asterix to denote a required field */
    required?: boolean
    /** used to provide tooltip text on label hover */
    hint?: string
}

/** LabelledInput: component that returns a label accessibly linked to the passed in Input */
const LabelledInput = forwardRef<HTMLInputElement, LabelledInputProps>((props, ref) => {
    const {
        labelColor = 'primary-blue',
        isErrored = false,
        htmlFor,
        label,
        labelHidden = false,
        children,
        className,
        required,
        disabled,
        hint,
        ...rest
    } = props

    const childrenProps = {
        ...rest,
        disabled,
        id: htmlFor,
        ref,
        required,
        isErrored,
    }

    const childrenWithProps = React.Children.map(children, (child) => {
        // Checking isValidElement is the safe way and avoids a typescript error too.
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...childrenProps })
        }
        return child
    })

    const labelClassNames = classnames(styles.label, {
        'visually-hidden': labelHidden,
    })

    const containerClassNames = classnames(styles.container, {
        [`${className}`]: className,
    })

    return (
        <div className={containerClassNames}>
            {hint ? (
                <Label
                    htmlFor={htmlFor}
                    className={labelClassNames}
                    disabled={disabled}
                    required={required}
                    labelColor={labelColor}
                >
                    <Tooltip
                        id={`${htmlFor}-label-hint`}
                        content={hint}
                        onDarkBackground={false}
                        position='top-start'
                    >
                        {label}
                    </Tooltip>
                </Label>
            ) : (
                <Label
                    htmlFor={htmlFor}
                    className={labelClassNames}
                    disabled={disabled}
                    required={required}
                    labelColor={labelColor}
                >
                    {label}
                </Label>
            )}
            {childrenWithProps}
        </div>
    )
})

LabelledInput.displayName = 'LabelledTextInput'

export default LabelledInput
