import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { startOfTomorrow } from 'date-fns'
import { DefaultOptions } from '@apollo/client'

import ColoredLine from 'components/basics/ColoredLine/ColoredLine'
import Icon from 'components/basics/Icon/Icon'
import PassengerConfiguration from 'components/sections/cruise/PassengerConfiguration/PassengerConfiguration'
import SearchBar from 'components/sections/cruise/SearchBar/SearchBar'
import Text from 'components/basics/Text/Text'
import { getSortedDestinationOptions } from 'components/layouts/cruise/SearchLayout/SearchLayout'
import {
    CRUISE_SEARCH_DATA_KEY,
    EXPIRY_TIME,
    getCruiseSearchQueryInput,
} from 'components/pages/cruise/SearchPage'
import { usePassengersInfo } from 'components/hooks/usePassengersInfo'
import { setDataToLocalStorage } from 'utils/use-local-storage'
import { createPassengersText } from 'utils/pluralisePassengers'
import { getFormattedDate } from 'utils/date-helpers'
import { INITIAL_FULL_PASSENGER_CONFIGURATION, APP_PAID_COMPANY_TIERS } from 'utils/constants'
import { createQueryParamString } from 'utils/create-query-param-string'
import createApolloClient from 'utils/apollo-client/create-apollo-client'
import { FeatureToggleContext } from 'App'
import { CruisesMetaData } from 'api-data-models/CruisesContentModel'

import styles from './ModifySearchBar.module.css'
import allContent from 'content/content'
import { DestinationCategory } from '../CategoryIndicator/CategoryIndicator'

const content = allContent.cruise.resultsPage.modifySearchBar

const options: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'cache-first', // check cache first as data is not changing
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'cache-first', // check cache first as data is not changing
        errorPolicy: 'all', // override default errorPolicy of 'none'
    },
}

const l3ApiClient = createApolloClient(process.env.REACT_APP_L3_API_URL, options)

type ModifySearchBarProps = {
    /** previous search params to start fields with */
    queryParams: Record<string, any>
    /** call back function for modify search bar to pass new search input values as url query params*/
    handleModifySearch(paramsString: string): void
    /** call back to trigger new search */
    makeNewSearch?(): void
    /** Data for driving the autoSuggest */
    cruisesMetaData?: CruisesMetaData
    /** For saved params (pre-populated form), need to know the category to submit string value to api - or use 'destination' as default */
    prePopulateDestinationCategory?: DestinationCategory
    companyTier: CompanyTierTypes
}

const ModifySearchBar = ({
    prePopulateDestinationCategory,
    queryParams,
    handleModifySearch,
    makeNewSearch,
    cruisesMetaData,
    companyTier,
}: ModifySearchBarProps): JSX.Element => {
    const featureToggles = useContext(FeatureToggleContext)
    const useAutoSuggest =
        !!cruisesMetaData?.numberOfResults && // can't use next check without first checking if cruisesMetaData exists
        cruisesMetaData.numberOfResults > 0 && // only show auto suggest if there are results to populate suggestions
        featureToggles.TURN_ON_SEARCH_AUTO_SUGGEST

    /** SORT THE DESTINATION CATEGORIES */
    const destinationData = useMemo(() => {
        if (cruisesMetaData) return getSortedDestinationOptions(cruisesMetaData)
        return []
    }, [cruisesMetaData])

    const { passengerConfigurationData, setPassengers } = usePassengersInfo()

    const [passengerConfiguration, setPassengerConfiguration] = useState<Record<string, any>>(
        passengerConfigurationData
    )
    const [shouldUpdatePassengerData, setShouldUpdatePassengerData] = useState<boolean>(true)
    const embarkEarliestDateDefaulted =
        queryParams?.embarkEarliestDate || new Date(startOfTomorrow()).toISOString()
    const embarkLatestDateDefaulted = queryParams?.embarkLatestDate ?? ''

    const destinationDefault =
        queryParams?.destination ||
        queryParams?.country ||
        queryParams?.supplierName ||
        queryParams?.shipName ||
        queryParams?.visitingPort

    const formFns = useForm({
        // sets default form values from Query params for the search bar inputs if values present in local storage
        defaultValues: {
            destination: destinationDefault ?? '',
            ...(embarkEarliestDateDefaulted && {
                embarkEarliestDate: new Date(embarkEarliestDateDefaulted),
            }),
            ...(embarkLatestDateDefaulted && {
                embarkLatestDate: new Date(embarkLatestDateDefaulted),
            }),
            ...(queryParams?.durationMin && {
                duration: `${queryParams?.durationMin}-${queryParams?.durationMax}`,
            }),
        },
    })

    const setDefaultPassengerConfiguration = useCallback(() => {
        setPassengers(INITIAL_FULL_PASSENGER_CONFIGURATION)
        setPassengerConfiguration(INITIAL_FULL_PASSENGER_CONFIGURATION)
        setShouldUpdatePassengerData(true)
    }, [setPassengers])

    useEffect(() => {
        if (!shouldUpdatePassengerData) return
        if (!passengerConfigurationData?.adults) {
            setDefaultPassengerConfiguration()
        } else {
            setPassengerConfiguration(passengerConfigurationData)
        }
        if (passengerConfiguration?.adults || passengerConfiguration?.children) {
            setShouldUpdatePassengerData(false)
        }
    }, [
        passengerConfiguration,
        shouldUpdatePassengerData,
        passengerConfigurationData,
        setDefaultPassengerConfiguration,
    ])

    // GET TEXT VALUES FOR DISPLAY OF QUERY VALUES
    const destinationText = destinationDefault || content.noDestinationSpecifiedText
    const embarkEarliestDate = getFormattedDate(embarkEarliestDateDefaulted)
    const embarkLatestDate = queryParams?.embarkLatestDate
        ? getFormattedDate(queryParams.embarkLatestDate)
        : null
    const embarkDateText = embarkLatestDate
        ? `${embarkEarliestDate} - ${embarkLatestDate}`
        : embarkEarliestDate
    const durationMin = queryParams?.durationMin
    const durationMax = queryParams?.durationMax
    const durationTexted =
        durationMax === 999
            ? `+${durationMin} ${content.durationUnits}`
            : `${durationMin}-${durationMax} ${content.durationUnits}`
    const durationText = durationMin ? durationTexted : content.durationPlaceHolder
    const onSubmit = (formFields: Record<string, any>): void => {
        const queryInput = getCruiseSearchQueryInput(formFields)
        const queryParamsString = createQueryParamString(queryInput)
        setDataToLocalStorage({
            data: formFields,
            key: CRUISE_SEARCH_DATA_KEY,
            expiryMins: EXPIRY_TIME,
        })
        handleModifySearch(queryParamsString)
        if (makeNewSearch) makeNewSearch()
    }

    return (
        <FormProvider {...formFns}>
            <div className={styles.container}>
                <div className={styles['previous-search-text']}>
                    <div className={styles['icon-text']}>
                        <Icon iconName='Location' iconColor='white' iconSize='S' />
                        <Text color='white' weight='bold'>
                            {destinationText}
                        </Text>
                    </div>
                    <Text color='divider-gray' size='M'>
                        |
                    </Text>
                    <div className={styles['icon-text']}>
                        <Icon iconName='Calendar' iconColor='white' iconSize='S' />
                        <Text color='white'>{embarkDateText}</Text>
                    </div>
                    <Text color='divider-gray' size='M'>
                        |
                    </Text>
                    <div className={styles['icon-text']}>
                        <Icon iconName='CalendarDays' iconColor='white' iconSize='S' />
                        <Text color='white'>{durationText}</Text>
                    </div>
                    <Text color='divider-gray' size='M'>
                        |
                    </Text>
                    <div className={styles['icon-text']}>
                        <Icon iconName='User' iconColor='white' iconSize='S' />
                        <Text color='white'>
                            {createPassengersText(
                                passengerConfiguration?.adults,
                                passengerConfiguration?.children
                            )}
                        </Text>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles['search-bar-and-title']}>
                        <SearchBar
                            onSubmit={onSubmit}
                            useAutoSuggest={useAutoSuggest}
                            destinationData={destinationData}
                            prePopulateDestinationCategory={prePopulateDestinationCategory}
                        />
                    </div>
                    {companyTier && APP_PAID_COMPANY_TIERS.includes(companyTier) && (
                        <>
                            <ColoredLine onDarkBackground={true} />
                            <PassengerConfiguration
                                setShouldUpdatePassengerData={setShouldUpdatePassengerData}
                                l3ApiClient={l3ApiClient}
                            />
                        </>
                    )}
                </div>
            </div>
        </FormProvider>
    )
}

export default ModifySearchBar
