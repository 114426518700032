export const convertPaymentOptionsToUrlQueryParameters = (
    paymentOption: PaymentOptionType
): string => {
    const paymentOptionsURLQueryParams = {
        ONHOLD: encodeURI('On Hold'),
        DEPOSIT: 'Deposit',
        FULL: 'Full',
    }
    return paymentOptionsURLQueryParams[paymentOption]
}

export const orderServiceUrls = {
    getOrders: (): string => `${process.env.REACT_APP_ORDER_SERVICE_URL}/orders`,
    getOrdersPaginated: (): string => `${process.env.REACT_APP_ORDER_SERVICE_URL}/orders/paginated`,
    getOrder: (orderId: string): string =>
        `${process.env.REACT_APP_ORDER_SERVICE_URL}/order/${orderId}`,
    createOrder: (): string => `${process.env.REACT_APP_ORDER_SERVICE_URL}/order_create`,
    updateOrderContact: (orderId: string): string =>
        `${process.env.REACT_APP_ORDER_SERVICE_URL}/order/${orderId}/contact_details_update`,
    updateOrderOptionalExtras: (orderId: string, itemId: string, supplierCode: string): string =>
        `${process.env.REACT_APP_ORDER_SERVICE_URL}/order/${orderId}/item/${itemId}/supplier/${supplierCode}/optional_extras_set`,
    importOrder: (orderId: string, supplierCode: string): string =>
        `${process.env.REACT_APP_ORDER_SERVICE_URL}/import/${orderId}/supplier/${supplierCode}`,
    retrieveOrder: (orderId: string, supplierCode: string): string =>
        `${process.env.REACT_APP_ORDER_SERVICE_URL}/retrieve/${orderId}/supplier/${supplierCode}`,
    addOrderTraveller: (orderId: string): string =>
        `${process.env.REACT_APP_ORDER_SERVICE_URL}/order_traveller/${orderId}/add`,
    updateOrderTraveller: (orderId: string, travellerNumber: string): string =>
        `${process.env.REACT_APP_ORDER_SERVICE_URL}/order_traveller/${orderId}/update/${travellerNumber}`,
    assignOrderTraveller: (orderId: string, itemId: string): string =>
        `${process.env.REACT_APP_ORDER_SERVICE_URL}/order_traveller/${orderId}/item/${itemId}/assign`,
    assignOrderLeadTraveller: (orderId: string, itemId: string, travellerNumber: string): string =>
        `${process.env.REACT_APP_ORDER_SERVICE_URL}/order_traveller/${orderId}/item/${itemId}/lead_traveller/${travellerNumber}/assign`,
    orderItemBook: (
        orderId: string,
        itemId: string,
        paymentOption: PaymentOptionType,
        paymentAmount: number,
        paymentCurrencyCode: string
    ): string => {
        const paymentOptionUrlQueryParam =
            'payment_option=' + convertPaymentOptionsToUrlQueryParameters(paymentOption)
        const paymentAmoutUrlQueryParam = '&payment_amount=' + paymentAmount

        const currencyUrlQueryParam =
            paymentCurrencyCode && paymentOption !== 'ONHOLD'
                ? '&payment_currency_code=' + paymentCurrencyCode
                : ''
        return (
            process.env.REACT_APP_ORDER_SERVICE_URL +
            `/order/${orderId}/item/${itemId}/book?${paymentOptionUrlQueryParam}${paymentAmoutUrlQueryParam}${currencyUrlQueryParam}`
        )
    },
}
