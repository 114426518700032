import { ROUTES } from 'components/sections/app/AppRoutes'
import React, { useCallback, useEffect } from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'
import { FEATUREBASE_ORG, nonProductionEnvironments } from 'utils/constants'

type FeaturebaseUserData = {
    email: string
    name: string
    id: string
}

const FeaturebaseEmbed: React.FC<{
    userData: FeaturebaseUserData | null
    onError: (err: any) => void
}> = ({ userData, onError }) => {
    useEffect(() => {
        const win: any = window
        if (win?.Featurebase) {
            win.Featurebase(
                'identify',
                {
                    organization: FEATUREBASE_ORG,
                    ...userData,
                },
                (err: any) => {
                    if (err) {
                        onError(err)
                    } else {
                        win.Featurebase('embed', {
                            /* Required */
                            organization: FEATUREBASE_ORG, // Replace with your organization name
                            /* Optional */
                            basePath: ROUTES.FEATURE_PORTAL, // Sync urls between your website & embed
                            theme: 'light', // options: light [default], dark
                            initialPage: 'Board', // options: Board [default], Changelog, Roadmap
                            hideMenu: false, // Hides the top navigation bar. Necessary because we not allow user to sign out manually, so they will always with the same account (same username and email) they have in our app.
                            hideLogo: true, // Hides the logo in the top navigation bar & leaves the Sign In button visible.
                            filters: null, // Default filters to apply to the board view
                            metadata: null, // Attach session-specific metadata to feedback
                            locale: 'en', // Change the language
                        })
                    }
                }
            )
            const observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.type === 'childList') {
                        const iframeElement = document.getElementById('featurebase-embed-iframe')
                        if (iframeElement) {
                            iframeElement.style.height = '100%'
                            observer.disconnect() // Stop observing once the iframe is found and styled
                        }
                    }
                })
            })
            // Start observing the container where the iframe will be added
            const targetNode = document.querySelector('[data-featurebase-embed]')
            if (targetNode) {
                observer.observe(targetNode, { childList: true })
            }

            return () => {
                observer.disconnect() // Clean up the observer on unmount
            }
        }
    }, [userData, onError])

    return <div data-featurebase-embed style={{ width: '100%' }} />
}

const FeaturebaseEmbedPage: React.FC = () => {
    const hideFeaturebase = nonProductionEnvironments.includes(
        process.env.REACT_APP_ENVIRONMENT_NAME ?? ''
    )

    const userContext = datadogLogs.getGlobalContext() as GlobalContextUserData
    const userDataParsed = extractGlobalContextUserData(userContext)
    const userData: FeaturebaseUserData = userDataParsed && {
        email: userDataParsed.userEmail,
        name: userDataParsed.userName,
        id: userDataParsed.userId,
    }

    const handleError = useCallback(
        (error: any) => {
            datadogLogs.logger.error(`source: FeaturebaseEmbedIdentify`, { userContext }, error)
        },
        [userContext]
    )

    if (hideFeaturebase)
        return (
            <div style={{ margin: '4rem auto' }}>
                Feedback page is not available in this environment
            </div>
        )
    if (userData) return <FeaturebaseEmbed userData={userData} onError={handleError} />
    return null
}

export default FeaturebaseEmbedPage
