import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ROUTES } from 'components/sections/app/AppRoutes'

import ResultsLayout from 'components/layouts/cruise/ResultsLayout/ResultsLayout'

import { useRest, UseRestOptions } from '../../hooks/useRest'
import { VITALLY_EVENTS } from 'utils/constants'
import { CruiseSearchResult, Cruise, CruisesMetaData } from 'api-data-models/CruisesContentModel'
import { MetaDataItem, APICruisesResponse } from 'api-data-models/cruises-api-types'
import * as self from './ResultsPage'
import { setDataToLocalStorage } from 'utils/use-local-storage'
import { CRUISE_SEARCH_DATA_KEY, EXPIRY_TIME } from './SearchPage'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'

/** parseQueryParams: take a query string and parse key/values from it into an object
 * @param {string} queryParamsString - query string params with leading ? removed */
export const composeSearchQueryVariables = (
    queryParamsString: string
): Record<string, string | number> => {
    const urlSearchParams = new URLSearchParams(queryParamsString)
    const paramsObject = Object.fromEntries(urlSearchParams.entries())
    const {
        destinationInputCategory,
        country,
        destination,
        embarkEarliestDate,
        embarkLatestDate,
        durationMin,
        durationMax,
        supplierCode,
        supplierName,
        shipName,
        embarkPort,
        disembarkPort,
        cruiseId,
        leadinPriceMin,
        leadinPriceMax,
        cabinType,
        productName,
        visitingPort,
    } = paramsObject
    const parsedDurationMin = parseInt(durationMin)
    const parsedDurationMax = parseInt(durationMax)

    // return only expected params, do not consume anything else from the url
    return {
        ...(destination && { destination }),
        ...(destinationInputCategory && { destinationInputCategory }),
        ...(visitingPort && { visitingPort }),
        ...(country && { country }),
        ...(embarkEarliestDate && { embarkEarliestDate }),
        ...(embarkLatestDate && { embarkLatestDate }),
        ...(durationMin && { durationMin: parsedDurationMin }),
        ...(durationMax && { durationMax: parsedDurationMax }),
        ...(supplierCode && { supplierCode }),
        ...(supplierName && { supplierName }),
        ...(shipName && { shipName }),
        ...(embarkPort && { embarkPort }),
        ...(disembarkPort && { disembarkPort }),
        ...(cruiseId && { cruiseId }),
        ...(leadinPriceMin && { leadinPriceMin }),
        ...(leadinPriceMax && { leadinPriceMax }),
        ...(cabinType && { cabinType }),
        ...(productName && { productName }),
    }
}

/** ResultsPage: renders a results page that calls the Cruise API on render */
const ResultsPage: React.FC<{ cruisesMetaData: CruisesMetaData }> = ({ cruisesMetaData }) => {
    const [cruises, setCruises] = useState<Cruise[] | undefined>(undefined)
    const [cruiseProductNames, setCruiseProductNames] = useState<string[] | undefined>([])
    const [departurePorts, setDeparturePorts] = useState<MetaDataItem[]>([])
    const [arrivalPorts, setArrivalPorts] = useState<MetaDataItem[]>([])

    const windowSearchParams = window.location.search
    const paramsObject = self.composeSearchQueryVariables(windowSearchParams)

    const fetchOptions: UseRestOptions = {
        url: process.env.REACT_APP_CRUISE_SEARCH_SERVICE_URL + '/cruises',
        variables: paramsObject,
        source: 'ResultsPage - GET_CRUISES',
        method: 'POST',
    }

    const { result, loading, error, refetch } = useRest(fetchOptions)

    const navigate = useNavigate()

    function handleModifySearch(paramsString: string): void {
        navigate(`${ROUTES.CRUISE_RESULTS}/?${paramsString}`)
    }

    useEffect(() => {
        if (Object.keys(paramsObject).length > 0) {
            setDataToLocalStorage({
                data: paramsObject,
                key: CRUISE_SEARCH_DATA_KEY,
                expiryMins: EXPIRY_TIME,
            })
        }
    }, [paramsObject])

    function makeNewSearch(): void {
        setCruises(undefined) // clear results out of local state when modify search triggers another query
        refetch()
    }

    useEffect(() => {
        CustomerSuccess.track({
            eventName: VITALLY_EVENTS.SEARCH_CRUISES,
            properties: {
                destination: paramsObject.destination,
                duration: paramsObject.duration,
                embarkEarliestDate: paramsObject.embarkEarliestDate,
                embarkLatestDate: paramsObject.embarkLatestDate,
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // only on mount only

    useEffect(() => {
        if (result?.searchResults && !loading) {
            const cruiseSearchResult: CruiseSearchResult = new CruiseSearchResult(
                result as APICruisesResponse
            )
            setCruises(cruiseSearchResult.cruises)
            setCruiseProductNames(cruiseSearchResult.allCruiseProductNames)
            setDeparturePorts(cruiseSearchResult.cruiseSearchMetaData.allDeparturePorts)
            setArrivalPorts(cruiseSearchResult.cruiseSearchMetaData.allArrivalPorts)
        }
    }, [
        loading,
        result?.searchResults,
        error,
        paramsObject.destination,
        paramsObject.duration,
        paramsObject.embarkEarliestDate,
        paramsObject.embarkLatestDate,
        result,
    ])

    // If result object exists, error is false, and cruises is undefined --> then the model is processing
    const processingResultData = !!result && !cruises && !error
    return (
        <div className='general-container'>
            <ResultsLayout
                loading={loading || processingResultData}
                apiError={error}
                cruiseProductNames={cruiseProductNames ?? []}
                departurePorts={departurePorts}
                arrivalPorts={arrivalPorts}
                cruises={cruises ?? []}
                queryParams={paramsObject}
                handleModifySearch={handleModifySearch}
                makeNewSearch={makeNewSearch}
                cruisesMetaData={cruisesMetaData}
            />
        </div>
    )
}

export default ResultsPage
