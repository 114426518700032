import React, { useEffect } from 'react'
import { nonProductionEnvironments } from 'utils/constants'

const LinkedInTrackingScript: React.FC = (): JSX.Element => {
    const hideLinkedInInsight = nonProductionEnvironments.includes(
        process.env.REACT_APP_ENVIRONMENT_NAME ?? ''
    )

    useEffect(() => {
        if (!hideLinkedInInsight) {
            // Adding the JavaScript code programmatically
            const win: any = window
            win._linkedin_partner_id = '6061194'
            win._linkedin_data_partner_ids = win._linkedin_data_partner_ids || []
            win._linkedin_data_partner_ids.push(win._linkedin_partner_id)
            ;(function (l): any {
                if (!l) {
                    win.lintrk = function (a: any, b: any): any {
                        win.lintrk.q.push([a, b])
                    }
                    win.lintrk.q = []
                }
                const s = document.getElementsByTagName('script')[0]
                const b = document.createElement('script')
                b.type = 'text/javascript'
                b.async = true
                b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'
                b.id = 'linkedin-insight-script' // Adding an ID for easier removal
                s?.parentNode?.insertBefore(b, s)
            })(win.lintrk)

            return () => {
                const linkedinScript = document.getElementById('linkedin-insight-script')
                if (linkedinScript) {
                    linkedinScript?.parentNode?.removeChild(linkedinScript)
                }
                // Clean up the global variables to prevent any potential memory leaks
                delete win._linkedin_partner_id
                delete win._linkedin_data_partner_ids
                delete win.lintrk
            }
        }
    }, [hideLinkedInInsight])

    if (hideLinkedInInsight) return <></>
    return (
        <>
            <noscript>
                <img
                    height='1'
                    width='1'
                    style={{ display: 'none' }}
                    alt=''
                    src='https://px.ads.linkedin.com/collect/?pid=6061194&fmt=gif'
                />
            </noscript>
        </>
    )
}

export default LinkedInTrackingScript
