import React, { useContext } from 'react'
import Heading from 'components/basics/Heading/Heading'
import OrderConfirmation from 'components/sections/order/OrderConfirmation/OrderConfirmation'
import OrderPricingSection from 'components/sections/order/OrderPricingSection/OrderPricingSection'
import OrderBookedPassengerDetails from 'components/sections/order/OrderBookedPassengerDetails/OrderBookedPassengerDetails'
import Spacing from 'components/basics/Spacing/Spacing'
import getRescardHtmlComment from 'utils/trams-rescard/getResCardHtmlComment'

import styles from './OrderImportLayout.module.css'
import {
    FlightProduct,
    OrderContent,
    PRODUCT_TYPES,
    CruiseProduct,
} from 'api-data-models/order/OrderContentModel'
import allContent from 'content/content'
import { datadogLogs } from '@datadog/browser-logs'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import { getAdvisoryMessages, getSummaryPrices } from '../OrderLayout/OrderLayout'
import { getFormattedDate } from 'utils/date-helpers'
import { FeatureToggleContext } from 'App'

const content = allContent.order.orderPage

type OrderLayoutProps = {
    orderData: OrderContent
}

const OrderImportLayout = ({ orderData }: OrderLayoutProps): JSX.Element | null => {
    const orderState = orderData
    const orderItem = orderState.orderItem
    const orderIdTrimmed = orderState.orderId ? orderState.orderId.slice(0, 8) : ''
    const featureToggles = useContext(FeatureToggleContext)

    let product
    let cruiseSummaryPrices = null

    if (orderItem?.product?.productType === PRODUCT_TYPES.CRUISE) {
        product = orderItem.product as CruiseProduct
        cruiseSummaryPrices = getSummaryPrices(
            product.pricing.totalGrossPriceCent,
            orderItem.passengersCriteria?.[0]?.assignedOptionalExtras || []
        )
    } else if (orderItem?.product?.productType === PRODUCT_TYPES.FLIGHT) {
        product = orderItem.product as FlightProduct
    }

    /** Do not show any components if no cruiseProduct or flightProduct is present as orderItem as they will break */
    if (!product || !orderState.orderItem) {
        return <p>{content.noProductOnOrder}</p>
    }

    const supplierOrderItemReference = orderItem.supplierOrderItemReference
    const orderItemStatus = orderItem.orderItemStatus
    const orderItemPaymentOption = orderItem.orderItemPaymentOption

    const assignedTravellers = orderState.orderItem.assignedTravellers
    const leadTraveller = orderState.orderItem.leadTraveller
    const travellers = { ...orderState.travellers }
    const numberOfPassengers = orderState.orderItem.numberOfTravellers
    const contactDetails = orderState.contactDetails
    let rescardComment

    try {
        rescardComment = getRescardHtmlComment({ order: orderState })
    } catch (error: any) {
        datadogLogs.logger.error(`error creating RESCARD:`, {}, error)
    }

    const advisoryMessages = orderItem?.advisory ? getAdvisoryMessages(orderItem.advisory) : null

    const isAdditionalInfoTurnedOn = featureToggles.TURN_ON_ORDERS_ADDITIONAL_INFO

    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <Spacing>
                    <Heading heading='1'>{content.importedBooking.title}</Heading>
                </Spacing>
            </div>
            <InfoBanner
                id='imported-banner'
                isCloseable={false}
                text={`${content.importedBooking.message}`}
                bannerType='success'
            />
            {advisoryMessages}
            <div className={styles.content}>
                <div className={styles['content-left']}>
                    {supplierOrderItemReference && (
                        <>
                            <OrderConfirmation
                                numberOfTravellers={numberOfPassengers}
                                orderItemStatus={orderItemStatus}
                                orderItemPaymentOption={orderItemPaymentOption}
                                product={product}
                                supplierOrderItemReference={supplierOrderItemReference}
                                orderId={orderIdTrimmed}
                                additionalData={
                                    isAdditionalInfoTurnedOn &&
                                    orderItem.passengersAdditionalInfo &&
                                    Object.values(orderItem.passengersAdditionalInfo).length
                                        ? orderItem.passengersAdditionalInfo
                                        : undefined
                                }
                            />
                            {!!rescardComment && rescardComment}
                        </>
                    )}
                </div>

                <div className={styles['content-right']}>
                    <OrderPricingSection
                        lastPriceUpdateTimeStamp={getFormattedDate(
                            new Date(orderData.orderItem.bookedAt ?? '').toISOString(),
                            'longWithTime'
                        )}
                        product={product}
                        orderId={orderIdTrimmed}
                        cruiseSummaryPrices={cruiseSummaryPrices}
                    />
                    <OrderBookedPassengerDetails
                        travellers={travellers}
                        assignedTravellerNumbers={assignedTravellers}
                        contactDetails={contactDetails}
                        leadTravellerNumber={leadTraveller as number}
                    />
                </div>
            </div>
        </div>
    )
}
export default OrderImportLayout
